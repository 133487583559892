<!--
 * @Author: zoujp
 * @Date: 2020-11-19 11:17:15
 * @LastEditTime: 2024-03-06 16:55:15
 * @LastEditors: Please set LastEditors
 * @Description: 主题列表视图
 * @FilePath: \user\src\views\themeSet\viewDefault\index.vue
-->
<template>
  <div class="theme-set">
    <div class="table-cent">
      <en-table
        ref="viewTable"
        :key="refData ? 'refData' + viewId : viewId"
        :data="defaultList"
        :hover-row-config="{
          dataMethod: buttonData,
          changeMethod: buttonClick,
        }"
        :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
        :class="{ 'index-table': refData }"
        height="100%"
        :flter-toolbar-visible="false"
        :height-config="refData ? {} : { bottomHeight: 10 }"
        @filter-sort-change="handleFilterSortChange"
        @row-click="handleRowClick"
        @row-hover="hoverRow"
        @row-leave="leaveRow"
      >
        <en-table-column type="index" width="60px"></en-table-column>
        <table-column
          v-for="(col, colindex) in colList"
          :key="col.id || colindex"
          :data="col"
          width="200"
        >
          <template v-slot="{ row, column }">
            {{ row | setField(column.field) }}
          </template>
        </table-column>
      </en-table>
    </div>
    <filterComm
      ref="popFilter"
      :fieldList="colList"
      :searchParams="conditions"
      :setBaseData="setBaseData"
      @filterSave="filterSave"
      @showBaseData="showBaseData"
    ></filterComm>
    <alertCompare
      ref="popCompare"
      :templateId="templateId"
      :btnData="btnData"
    ></alertCompare>
    <baseDataComm
      ref="popBaseData"
      :filterRowData="filterRowData"
      @saveBaseData="saveBaseData"
    ></baseDataComm>
    <alertExport
      ref="popExport"
      @toDownloadCenter="$refs.downDialog.open()"
      :templateId="templateId"
      :exportData="exportData"
    ></alertExport>
    <!-- 下载 -->
    <down-dialog ref="downDialog"></down-dialog>
    <enBusinessDownDialog
      v-if="businessDialogShow"
      :businessParams="businessParams"
      :dialogVisible.sync="businessDialogShow"
    >
    </enBusinessDownDialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { defaultListService } from "@/api";
import { viewBusinessDialogParams } from "@/components/businessDetail/helper.js";
import tableColumn from "@/components/en-table-extra/tableColumn";
import { BusinessDataOpenType } from "@/components/businessDetail/data/BusinessCommonHeader";
import { request } from "en-js";
import filterComm from "../components/filterComm";
import baseDataComm from "../components/selectBaseData";
import alertExport from "../components/alertExport";
import alertCompare from "../components/alertCompare";

export default {
  name: "ganttList",
  components: {
    tableColumn,
    alertCompare,
    alertExport,
    baseDataComm,
    filterComm
  },
  data() {
    return {
      tabeHeight: 0,
      enloading: false,
      templateId:
        this.$route.query.templateId || this.$attrs.menuData?.refData?.id, // 模板id
      templateName:
        this.$route.query.templateName || this.$attrs.menuData?.refData?.name, // 模板名称
      viewId: this.$route.query.viewId || this.$attrs.menuData?.refData?.viewId, // 视图id
      batchBtnList: [], // 顶部按钮数据集合
      tabList: [], // tab页签
      activeTab: "", // 当前激活tab页面id
      singleBtnList: [], // 列表中按钮数据集合
      colList: [], // 列投数据集合
      dataType: 0, // 1.基础数据 2.业务建模
      conditions: [], // 请求条件
      setBaseData: {}, // 类型为5时选择基础数据返回值回调填充筛选弹窗
      sortField: {}, // 排序字段
      isDefault: "",
      defaultData: {}, // 查询列表返回的数据
      defaultList: [], // 列表数据
      pageModel: {}, // 分页配置
      supernatantFields: [],
      shareUserId: "", // 共享人id
      filterRowData: {}, // 过滤的某一行数据
      btnData: {}, // 按钮数据
      has: false, // 穿透条件展示,
      exportData: {}, // 导出参数
      businessDialogShow: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      // this.queryTabs();
    });
  },
  filters: {
    /**
     * @description: 格式化单元格
     * @param {*} item
     * @param {*} field
     * @return {*}
     */
    setField(item, field) {
      const colItem = this.colList.find((a) => a.field === field);
      const colType = colItem?.fieldType;
      const value = item[field];
      let str = "";
      if (colType === 5 || colType === 15 || colType === 33) {
        if (value.length) {
          str = value.map((ele) => ele.name).join(",");
        }
      } else if (colType === 29) {
        if (value.provinceName) {
          str += value.provinceName;
        }
        if (value.cityName) {
          str += value.cityName;
        }
        if (value.districtName) {
          str += value.districtName;
        }
        if (value.address) {
          str += value.address;
        }
      } else {
        str = value;
      }
      return str;
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    refData() {
      console.log(this.$attrs.menuData?.refData);
      return this.$attrs.menuData?.refData;
    }
  },
  methods: {
    hoverRow(e) {
      const index = this.defaultList.findIndex((it) => it.id === e.id);
      this.$emit("curRow", index);
    },
    leaveRow(e) {
      if (e) {
        const index = this.defaultList.findIndex((it) => it.id === e.id);
        this.$emit("leaveRow", index);
      }
    },
    // 筛选
    handleFilterSortChange({ filterData, sortData }) {
      this.pageModel.pageNo = 1;
      const filter = JSON.parse(filterData || "[]");
      // 重复条件替换 zjh
      if (filter.length) {
        filter.forEach((element) => {
          let flg = false;
          this.conditions.forEach((element1, index1) => {
            if (element1.nameVariable === element.nameVariable) {
              flg = true;
              this.conditions[index1] = element;
            }
          });
          if (!flg) {
            this.conditions.push(element);
          }
        });
      } else {
        // 清除条件 filter为空数组
        this.conditions = [];
      }
      // this.conditions = [...this.conditions, ...filter];
      // 不含有 opType 操作符的 文本类型 需要操作符才可以使用 zjh
      this.conditions.forEach((element) => {
        if (
          ["1", "2"].indexOf(String(element.fieldType) > -1)
          && !element.opType
        ) {
          element.opType = 3;
        }
        element.field = element.nameVariable;
      });
      this.sortField = JSON.parse(sortData || "{}");
      this.queryDetailResult();
    },
    /**
     * @description: 切换tab页签
     * @param {*} item
     * @return {*}
     */
    changeTabs(item) {
      this.colList = [];
      this.defaultList = [];
      this.activeTab = item.id;
      this.changeConditions();
      this.queryListCol();
    },
    /**
     * @description: 查询视图tab页签
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async queryTabs() {
      const pa = {
        templateId: this.templateId,
        isPreview: 1,
        viewId: this.viewId
      };
      const rsp = await defaultListService.queryTabs(pa);
      this.tabList = rsp;
      this.activeTab = rsp[0].id;
      this.changeConditions();
      this.queryListCol();
    },
    // 根据页签获取管理端默认筛选条件
    changeConditions() {
      this.conditions = [];
      const arr = this.tabList.find((item) => item.id === this.activeTab);
      if (arr.tabRange) {
        const ranges = JSON.parse(arr.tabRange);
        ranges.map((item, ind) => {
          item.name = item.fieldName;
          item.field = item.nameVariable;
          return ind;
        });
        this.conditions = ranges;
      }
      this.setCondition(this.conditions);
    },
    setCondition(searchParams) {
      const tableRef = this.$refs.viewTable;
      if (!tableRef) return;
      tableRef.filterStore.dataList = searchParams.map((item) => ({
        ...item,
        field: item.nameVariable,
        prop: item.nameVariable,
        property: item.nameVariable,
        type: "filter",
        value: item.value,
        valueText: item.valueText,
        sort: item.sort,
        opType: item.opType,
        sortType: item.sortType,
        fieldType: item.fieldType,
        column: {
          opType: item.opType,
          opTypeText: item.name,
          title: item.fieldName,
          field: item.nameVariable,
          property: item.nameVariable,
          prop: item.nameVariable
        }
      }));
    },
    /**
     * @description: 查询列头、按钮 、样式等
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async queryListCol() {
      const pa = {
        templateId: this.templateId,
        isPreview: 1,
        viewId: this.viewId,
        tabId: this.activeTab
      };
      const rsp = await defaultListService.queryListCol(pa);
      this.batchBtnList = rsp.batchBtnList || [];
      this.$emit("batchBtnList", this.batchBtnList);
      this.singleBtnList = rsp.singleBtnList || [];
      this.singleBtnList.push({
        btnKey: "delete",
        btnName: "删除",
        colour: "",
        dataRange: "数据范围",
        funcType: "1",
        icon: "0"
      });
      // 列头数据
      this.colList = rsp.colList || [];
      // 1.基础数据 2.业务建模
      this.dataType = rsp.type || 0;
      // const cons = rsp.conditions || [];
      // console.log("cons", cons);
      // this.conditions = cons.filter((item) => item.nameVariable !== "finishType" && item.nameVariable !== "secretType");
      this.isDefault = rsp.isDefault;
      // zjh 看板穿透
      if (String(this.$route.query.screen) === "6") {
        this.conditions = [];
        const list = JSON.parse(sessionStorage.getItem("business_condition"))?.dataList
          || [];
        list.forEach((element) => {
          element.nameVariable = element.fieldNameVariable;
          element.field = element.fieldNameVariable;
          element.name = element.fieldNameVariable;
        });
        this.conditions = list;
        this.setCondition(this.conditions);
        this.conditions.forEach((element) => {
          if (
            ["1", "2"].indexOf(String(element.fieldType) > -1)
            && !element.opType
          ) {
            element.opType = 3;
          }
          element.field = element.nameVariable;
        });
      }
      this.queryDetailResult();
    },
    /**
     * @description: 查看列表数据
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async queryDetailResult() {
      const pa = {
        templateId: this.templateId,
        isPreview: 1,
        viewId: this.viewId,
        tabId: this.activeTab,
        pageSize: this.pageModel.pageSize || 10,
        pageNo: this.pageModel.pageNo || 1,
        filterFields: JSON.stringify(this.conditions),
        sortField: JSON.stringify(this.sortField),
        shareUserId: this.shareUserId
      };
      const rsp = await defaultListService.queryDetailResult(pa);
      const obj = rsp || {};
      this.defaultData = obj;
      this.defaultList = this.defaultData.records;
      this.$emit("dataList", this.defaultList);
      this.pageModel = {
        pageNo: this.defaultData.pageNo || 1,
        pageSize: this.defaultData.pageSize || 10,
        total: this.defaultData.total || "",
        totalPages:
          this.defaultData.totalPages
          || Math.ceil(this.defaultData.total / (this.defaultData.pageSize || 10))
      };
      // // zjh 看板穿透 设置条件
      if (String(this.screen) === "6") {
        this.$nextTick(() => {
          if (this.has) return;
          this.$refs.viewTable.filterStore.dataList = JSON.parse(sessionStorage.getItem("business_condition"))
              ?.dataList || [];
          this.has = true;
        });
      }
    },
    /**
     * @description: 分页回调
     * @param {*}
     * @return {*}
     */
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.pageModel.pageSize = pageSize;
      this.pageModel.pageNo = pageNo;
      this.queryDetailResult();
    },
    /**
     * @description: 行按钮
     * @param {*}
     * @return {*}
     */
    /**
     * @description
     * @param 表格操作列按钮
     */
    buttonData({ row }) {
      console.log(row, "rowrowrowrow");
      const btnList = cloneDeep(this.singleBtnList);
      const resBtn = [];
      const createId = row.createId.length && row.createId[0].id;

      if (row.addType !== 2) {
        btnList.pop();
        btnList.filter((item) => item.btnKey !== "delete");
      }
      if (createId === this.userInfo.userId) {
        btnList.forEach((item) => {
          if (item.btnKey === "encrypt") {
            if (row.secretType === 1) {
              resBtn.push({
                icon: "iconsimi",
                id: "btn-simi-cancel",
                name: `取消${item.btnName}`,
                iconColor: "#aeaeae"
              });
            } else {
              resBtn.push({
                icon: "iconsimi",
                id: "btn-simi",
                name: item.btnName,
                iconColor: "#4793de"
              });
            }
          } else if (item.btnKey === "finish") {
            if (row.finishType === 1) {
              resBtn.push({
                icon: "iconwancheng",
                id: "btn-wancheng-cancel",
                name: `取消${item.btnName}`,
                iconColor: "#aeaeae"
              });
            } else {
              resBtn.push({
                icon: "iconwancheng",
                id: "btn-wancheng",
                name: item.btnName,
                iconColor: "#4793de"
              });
            }
          } else {
            resBtn.push({
              icon: "iconshanchu-liebiao",
              id: "btn-shanchu",
              name: "删除",
              iconColor: "#fa6a6a"
            });
          }
        });
      }
      return resBtn;
    },
    /**
     * @description
     * @param 表格操作列按钮事件
     */
    buttonClick({ row, index, btnData }) {
      console.log(index);
      switch (btnData.id) {
        case "btn-wancheng": // 完成
          this.wanchengRow(row, btnData.name);
          break;
        case "btn-wancheng-cancel": // 取消完成
          this.wanchengCancelRow(row, btnData.name);
          break;
        case "btn-simi": // 私密
          this.simiRow(row, btnData.name);
          break;
        case "btn-simi-cancel": // 取消私密
          this.simiCancelRow(row, btnData.name);
          break;
        case "btn-shanchu": // 删除
          this.deleteRow(row, btnData.name);
          break;
        default:
          break;
      }
    },
    /**
     * @description: 完成
     * @param {*} row
     * @param {*} index
     * @return {*}
     */
    @request(true, "enloading")
    async wanchengRow(row, btnName, type) {
      const params = {
        templateId: this.templateId,
        id: row.id,
        refType: row.refType,
        finishType: type || 1
      };
      await defaultListService.ptFinish(params);
      this.$message(`${btnName}成功`);
      setTimeout(() => {
        this.queryDetailResult();
      }, 500);
    },
    /**
     * @description: 取消完成
     * @param {*} row
     * @return {*}
     */
    wanchengCancelRow(row, btnName) {
      this.wanchengRow(row, btnName, 2);
    },
    /**
     * @description: 私密
     * @param {*} row
     * @return {*}
     */
    @request(true, "enloading")
    async simiRow(row, btnName, type) {
      const params = {
        templateId: this.templateId,
        id: row.id,
        refType: row.refType,
        secretType: type || 1
      };
      await defaultListService.ptSecret(params);
      this.$message(`${btnName}成功`);
      setTimeout(() => {
        this.queryDetailResult();
      }, 500);
    },
    /**
     * @description: 取消私密
     * @param {*} row
     * @return {*}
     */
    simiCancelRow(row, btnName) {
      this.simiRow(row, btnName, 2);
    },
    /**
     * @description: 删除
     * @param {*} row
     * @return {*}
     */
    deleteRow(row) {
      this.$confirm("确定删除已选择的1条数据", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        defaultListService.ptOut({ templateId: this.templateId, id: row.id });
        this.$message("删除成功");
        setTimeout(() => {
          this.queryDetailResult();
        }, 500);
      });
    },
    /**
     * @description: 分享回调
     * @param {*} share
     * @return {*}
     */
    saveCallback(share) {
      this.shareUserId = share.shareUserId;
      this.queryDetailResult();
    },
    /**
     * @description: 过滤按钮回调
     * @param {*}
     * @return {*}
     */
    filterChange() {
      this.$refs.popFilter.visiable = true;
    },
    /**
     * @description: 导出按钮回调
     * @param {*}
     * @return {*}
     */
    exportChange() {
      this.$refs.popExport.visible = true;
      const {
        templateId,
        activeTab,
        shareUserId,
        conditions,
        colList,
        sortField
      } = this;
      this.exportData = {
        templateId,
        activeTab,
        shareUserId,
        filterFields: conditions,
        sortField,
        showList: colList
      };
    },
    /**
     * @description: 对比按钮回调
     * @param {*}
     * @return {*}
     */
    compareChange(btnData) {
      this.$refs.popCompare.visiable = true;
      this.btnData = btnData;
    },
    /**
     * @description: 选择基础数据
     * @param {*}
     * @return {*}
     */
    showBaseData(row) {
      this.$refs.popBaseData.visiable = true;
      this.filterRowData = row;
    },
    /**
     * @description:基础数据内容保存
     * @param {*}
     * @return {*}
     */
    saveBaseData(par) {
      this.setBaseData = par;
    },
    /**
     * @description: 过滤弹窗回调
     * @param {*} cons
     * @return {*}
     */
    filterSave(re) {
      this.conditions = re.filterData || [];
      this.setCondition(this.conditions);
      this.queryDetailResult();
    },
    /**
     * @description: 查看业务建模详情
     * @param {*} cons
     * @return {*}
     */
    handleRowClick({ row }) {
      const { refType, businessType } = row;
      if ([3, 22].includes(refType)) {
        let params = {
          templateId: this.templateId,
          businessType,
          openType: BusinessDataOpenType.view,
          originOpenBusinessType: "theme"
        };
        params = Object.assign(params, { id: row.businessKey });
        this.businessParams = viewBusinessDialogParams(params);
        this.businessDialogShow = true;
      } else if ([20, 21].includes(refType)) {
        // 待办 已办
        if (["1", "2"].includes(businessType)) {
          let params = {
            templateId: this.templateId,
            businessType,
            openType: BusinessDataOpenType.view,
            originOpenBusinessType: "theme"
          };
          params = Object.assign(params, { id: row.businessKey });
          this.businessParams = viewBusinessDialogParams(params);
          this.businessDialogShow = true;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.view-table {
  // height: calc(100% - 50px);
  height: 100%;
  margin-top: -1px;
  .table-cent {
    height: calc(100% - 60px);
  }
}
/deep/ .en-tree-node .en-tree-node__label-wrapper {
  max-width: 300px;
}
/deep/ .vxe-table.t--border .vxe-body--column {
  height: 54px !important;
}
/deep/ .vxe-header--row .vxe-header--column.col--ellipsis {
  height: 51px !important;
}
.theme-set {
  /deep/ .vxe-table--body-wrapper {
    overflow: hidden;
  }
  /deep/ .vxe-table--body-wrapper:hover {
    overflow: auto;
  }
}
</style>
