<!-- eslint-disable consistent-return -->
<!--
 * @Author: gt
 * @Description: 主题甘特图视图
 * @FilePath: /user/src/views/themeSet/gantt
-->
<template>
  <div ref="gantt_scrollbar" class="gantt-box">
    <div class="theme-set" v-en-loading="enloading">
      <div class="topbar">
        <!-- <div class="pull-left">
          <viewComm
            :templateId="templateId"
            :viewId="'甘特图'"
            :templateName="'甘特图视图'"
          ></viewComm>
        </div> -->
        <div class="pull-left">
          <en-icon  class="back-icon" name="iconfanhuishangcengji" size="18px"
               @click.native="handleBackIconClick"></en-icon>
          <div class="header-icon">
            <en-icon  name="iconmoban-yewujianmo1" size="16"
                  color="#FCFCFC"></en-icon>
          </div>
          <span class="title-text">{{ templateName || '' }}</span>
          <themeViewComm :templateId="templateId" :viewId="viewId" :templateName="templateName"></themeViewComm>
      </div>
        <div class="pull-right">
            <btnComm :btnList="batchBtnList" :templateId="templateId" @filterChange="filterChange" @compareChange="compareChange" @exportChange="exportChange"></btnComm>
        </div>
        <!-- <div class="pull-center">
            <shareComm :templateName="'甘特图视图'" :templateId="templateId" @saveCallback="saveCallback"></shareComm>
        </div> -->
      </div>
    </div>
    <div class="main" @mouseup="mouseup">
      <div class="table" :style="{ 'width': `${tableWidth}px` }">
        <div class="table-box">
          <gantt-list @leaveRow="leaveRow" @curRow="getCurRow" @dataList="getDateList" @batchBtnList="getBatchBtnList" class="table-list" ref="gantList"></gantt-list>
        </div>

        <div v-show="showModel" class="btn-icon roate-left" v-if="isOpen" @click="unpack">
          <span class="iconfont">&#xe85c;</span>
        </div>
        <div v-show="showModel" v-else class="btn-icon roate-right" @click="unpack">
          <span class="iconfont">&#xe85c;</span>
        </div>
        <div v-show="showModel" class="drag" @mousedown.stop="mousedown"></div>
      </div>
      <div
      v-show="showModel"
        ref="gantt"
        class="gantt-container"
        @mousemove="ganttMove"
        @mouseleave="gantLeave"
      ></div>
      <!-- 图表右侧按钮 -->
      <div class="change-btn-box" v-show="showModel">
        <div class="todat-btn" @click="today">今日</div>
        <el-select
          class="select-time"
          @change="updateTime"
          v-model="gantTimeShow"
          placeholder="请选择"
        >
          <el-option
            v-for="item in gantTimeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { gantt } from "dhtmlx-gantt";
import { defaultListService } from "@/api";
import { request } from "en-js";
import { newdebounce } from "@/components/businessDetail/components/columnRendering/text/util.js";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import dayjs from "dayjs";
import btnComm from "../components/btnComm";
import shareComm from "../components/shareComm";
import viewComm from "../components/viewComm";
import GanttList from "./ganttList.vue";
import themeViewComm from "../components/themeViewComm";

export default {
  name: "gantt",
  components: {
    btnComm,
    // shareComm,
    // viewComm,
    themeViewComm,
    GanttList
  },
  props: {
    ganttTasks: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      showModel: false, // 初始化隐藏 数据加载完毕再显示
      colList: [], // 列投数据集合
      gantTimeShow: "M",
      gantTimeList: [
        {
          label: "月",
          value: "M",
          time: [
              dayjs().startOf("month").format("YYYY-MM-DD"),
              dayjs().endOf("month").format("YYYY-MM-DD")
              ]
        },
        {
          label: "周",
          value: "W",
          time: [
            new Date(
              new Date().getTime()
                - 3600 * 1000 * 24 * (new Date().getDay() - 1)
            ),
            new Date(
              new Date().getTime()
                + 3600 * 1000 * 24 * (7 - new Date().getDay())
            )
          ]
        },
        {
          label: "季",
          value: "季",
          time: [
            new Date(
              new Date().getFullYear(),
              Math.floor(new Date().getMonth() / 3) * 3,
              1
            ),
            new Date(
              new Date().getFullYear(),
              Math.floor(new Date().getMonth() / 3) * 3 + 3,
              0
            )
          ]
        }
      ],
      standby: [],
      viewId: this.$attrs.menuData?.refData?.viewId,
      marker: null,
      clickCount: 0,
      isDown: false,
      clientWidth: 0,
      tableWidth: 400,
      isOpen: true,
      enloading: false,
      templateId: this.$route.query.templateId || "",
      templateName: this.$route.query.templateName || "",
      ganttData: [],
      weekDay: ["日", "一", "二", "三", "四", "五", "六"],
      batchBtnList: [],
      time: [
      dayjs().startOf("month").format("YYYY-MM-DD"),
      dayjs().endOf("month").format("YYYY-MM-DD")
      ]
    };
  },
  mounted() {
    this.initGantt();
  },
  computed: {
    ...mapGetters(["userInfo"]),
    refData() {
      return this.$attrs.menuData?.refData;
    }
  },
  methods: {
     // 返回按钮点击
     handleBackIconClick() {
      this.$router.back();
    },
    /**
     * @description: 分享回调
     * @param {*} share
     * @return {*}
     */
     saveCallback(share) {
      this.$refs.gantList.saveCallback(share);
    },
    /**
     * @description: 对比按钮回调
     * @param {*}
     * @return {*}
     */
     compareChange(btnData) {
      this.$refs.gantList.compareChange(btnData);
    },
    /**
     * @description: 过滤按钮回调
     * @param {*}
     * @return {*}
     */
     filterChange() {
      this.$refs.gantList.filterChange();
    },
    /**
     * @description: 导出按钮回调
     * @param {*}
     * @return {*}
     */
    exportChange() {
      this.$refs.gantList.exportChange();
    },
    getBatchBtnList(list) {
      this.batchBtnList = list;
    },
    getStartTime(time) {
      if (time.length) { return time.split(",")[0]; }
      return "";
    },
    getendTime(time) {
      if (time.length) { return time.split(",")[1]; }
      return "";
    },
    getColorAndStatus(time) {
      const toDay = Date.parse(new Date());
      if (time.length) {
        let timeArr = time.split(",");
        if (timeArr.length === 2) {
          timeArr = timeArr.map((it) => Date.parse(new Date(it)));
          console.log(timeArr[1] > toDay, timeArr, toDay, "2");
          if (timeArr[0] > toDay) {
            return {
              color: "#3e90fe",
              status: 1
            };
          }
          if (timeArr[1] < toDay) {
            return {
              color: "#4caf50",
              status: 2
            };
          }
          if (toDay >= timeArr[0] && toDay <= timeArr[1]) {
            return {
              color: "#f57c00",
              status: 3
            };
          }
        }
      }
      return {
          color: "",
          status: ""
      };
    },
    getDateList(list) {
      if (list.length) {
        this.ganttData = list.map((it, index) => ({
            id: index + 1,
            index: 1,
            text: it.P1[0].name,
            start_date: this.getStartTime(it.P6),
            end_date: this.getendTime(it.P6),
            status: this.getColorAndStatus(it.P6).status,
            color: this.getColorAndStatus(it.P6).color
          }));
      }
       // 为了满足ui样式迫不得已添加空数据占位
    for (let i = 0; i <= 18 - this.ganttData.length; i++) {
      this.standby.push({
        id: `10000${i}`,
        text: "",
        start_date: "",
        end_date: "",
        status: 0,
        color: ""
      });
    }
      this.ganttData = JSON.parse(JSON.stringify(this.ganttData.concat(this.standby)));
       // gantt初始化配置
       setTimeout(() => {
        this.getGanttData(); // 数据format以及gantt reload
       }, 50);
    },
    updateTime(value) {
      this.time = this.gantTimeList.find((it) => it.value === value).time;
      console.log(this.time);
      this.initGantt(); // gantt初始化配置
      this.getGanttData(); // 数据format以及gantt reload
    },
    today() {
      this.marker = null;
      const date = new Date(new Date().toLocaleDateString()).getTime()
        + 12 * 60 * 60 * 1000;
      this.marker = gantt.addMarker({
        start_date: date,
        css: "mark_line",
        text: ""
      });
      console.log(gantt.posFromDate(date));
      const x = gantt.posFromDate(date) > 300
          ? gantt.posFromDate(date) - 300
          : gantt.posFromDate(date);
      gantt.scrollTo(x, 0);
    },
    gantLeave() {
      console.log("bbbb");
      gantt.deleteMarker(this.marker);
      this.marker = null;
    },
    ganttMove(e) {
      const offset = e.target.offsetLeft
        ? e.target.offsetLeft + e.offsetX
        : e.offsetX;
      const pos = gantt.getScrollState();
      // // 获取鼠标当前所在位置在甘特图上的时间值
      const date = gantt.dateFromPos(offset + 2);
      // // 更新标记线位置
      if (!this.marker) {
        this.marker = gantt.addMarker({
          start_date: date,
          css: "mark_line",
          text: ""
        });
      } else {
        gantt.getMarker(this.marker).start_date = date;
        gantt.updateMarker(this.marker);
      }
      const curentRow = Number(e.target?.parentElement?.getAttribute("task_id"));
      const tbodyDom = document.getElementsByClassName("vxe-body--row");
      for (let index = 0; index < tbodyDom.length; index++) {
        if (index === curentRow - 1) {
          tbodyDom[index].style.background = "#ECF4FF";
        } else {
          tbodyDom[index].style.background = "";
        }
      }
    },
    moveMouse(e) {
      if (this.isDown) {
        if (this.clientWidth - e.clientX < 0) {
          this.tableWidth += 5;
        } else {
          this.tableWidth -= 5;
        }
        console.log(this.tableWidth);
        if (this.tableWidth <= 1) {
          this.isOpen = false;
        }
        newdebounce(() => {
          this.initGantt();
          this.getGanttData();
        }, 500);
      }
    },
    mousedown(e) {
      console.log(e);
      this.isDown = true;
      this.clientWidth = e.clientX;
      // 添加鼠标移动事件
      document.body.addEventListener("mouseup", this.mouseup);
      document.body.addEventListener("mousemove", this.moveMouse);
    },

    mouseup() {
      this.isDown = false;
      this.clientWidth = 0;
      document.body.removeEventListener("mousemove", this.moveMouse);
      document.body.removeEventListener("mousemove", this.mouseup);
    },
    // 展开收起
    unpack() {
      this.clickCount++;
      this.isOpen = !this.isOpen;
      this.tableWidth = this.isOpen ? 400 : 1;
      setTimeout(() => {
        this.initGantt();
        this.getGanttData();
      }, 500);
    },
    initGantt() {
      gantt.i18n.setLocale("cn");
      gantt.plugins({
        marker: true,
        tooltip: true
      });
      console.log(this.time, "this.timethis.timethis.time");
      gantt.config.start_date = this.time[0];
      gantt.config.end_date = this.time[1];
      gantt.config.show_tasks_outside_timescale = true;
      gantt.config.show_grid = false;
      // 在初始化前设置配置项
      // 拖动task
      gantt.config.drag_project = false;
      // gantt只读
      gantt.config.readonly = true;
      // 初始化gantt
      gantt.init(this.$refs.gantt);
      gantt.config.select_task = false;
      gantt.config.autofit = true;
      gantt.config.scale_height = 104;
      gantt.config.task_height = 24;
      gantt.config.row_height = 48;
      gantt.config.min_column_width = 110;
      gantt.config.date_format = "%Y-%m-%d";
      //   gantt.config.columns = [
      //     {
      //         name: "text",
      //         width: 160,
      //         label: "标题",
      //         align: "left",
      //         tree: true,
      //         template(obj) {
      //             // 通过 template 回调可以指定返回内容值
      //             return `
      //                 <div class="gantt-span-${obj.taskType}" style="width:130px;text-overflow:ellipsis;overflow: hidden;">${obj.taskNum}  ${obj.text}</div>
      //             `;
      //         }
      //     }
      // ];
      const yearStyle = () => "year-cell";
      const daysStyle = (date) => {
        const todayDate = new Date().setHours(0, 0, 0, 0);
        const paramsDate = new Date(date).setHours(0, 0, 0, 0);
        if (todayDate === paramsDate) {
          return "today task-cell";
        }
        if (date.getDay() === 0 || date.getDay() === 6) {
          return "weekend task-cell";
        }
        return "task-cell";
      };
      // 这里在时间轴表格内的cell上添加class
      // eslint-disable-next-line func-names, consistent-return
      gantt.templates.timeline_cell_class = (item, date) => {
        if (date.getDay() === 0 || date.getDay() === 6) {
          return "weekend";
        }
        return "";
      };
      const _getMonthWeek = (theDate) => {
        const currentDay = new Date(theDate);
        // 获取该日期所在周的周六，如2019.5月的周六有4号、11号、18号、25号、31号
        const theSaturday = currentDay.getDate() + (6 - currentDay.getDay());
        console.info(theSaturday); // 11
        if (this.gantTimeShow === "W") {
          return Math.ceil(theSaturday / 7);
        }
        const currMonth = currentDay.getMonth() + 1;
        return Math.floor(
          currMonth % 3 === 0 ? currMonth / 3 : currMonth / 3 + 1
        );
      };
      const showTitle = (date) => {
        if (this.gantTimeShow === "M") {
          return `${new Date(date).getFullYear()} 年 ${
            new Date(date).getMonth() + 1
          } 月`;
        }
        if (this.gantTimeShow === "W") {
          return `${new Date(date).getFullYear()} 年 ${
            new Date(date).getMonth() + 1
          } 月 第 ${_getMonthWeek(new Date())} 周`;
        }
        return `${new Date(date).getFullYear()} 年 第 ${_getMonthWeek(
          new Date()
        )} 季`;
      };
      const cellDate = (date) => `<span class="today-span">${this.weekDay[date.getDay()]} <br/> ${date.getDate()}</span>`;
      // 多表头
      gantt.config.scales = [
        {
          unit: "month",
          step: 1,
          format: showTitle,
          css: yearStyle
        },
        {
          unit: "day",
          step: 1,
          format: cellDate,
          css: daysStyle
        }
      ];
      const progress = (start, end) => {
        const startTime = Date.parse(new Date(start));
          const endTime = Date.parse(end);
          const now = Date.parse(new Date());

        // 计算经过的时间
        const elapsed = now - startTime;

        // 计算总的时间
        const total = endTime - startTime;

        // 计算进度百分比
        return parseFloat((elapsed / total) * 100).toFixed(2);
      };
      // 条形图里的文字
      // eslint-disable-next-line max-len
      gantt.templates.task_text = (start, end, task) => {
        if (task.text) {
          // eslint-disable-next-line max-len
          return `<div class="main-box"><span class="dian"></span><span class="task-name" style='text-align:left;'>${
            task.text
          }</span><span class="tip" style='color:${task.color};'>${
            task.status === 1 ? "未开始" : task.status === 2 ? "已完成" : `${progress(start, end)}%`
          }</span></div><span class="jin-du" style='width:${progress(start, end)}%'></span>`;
        }
        return "";
      };
      // 条形图自定义class
      gantt.templates.task_class = (start, end, task) => {
        if (task.status === 1) {
          return "task-not-progress";
        }
        if (task.status === 2) {
          return "task-success";
        }
        if (task.status === 3) {
          return "task-progress";
        }
        return "no-task";
      };
      // 自定义tooltip内容
      gantt.templates.tooltip_text = (start, end, task) => {
          const t = gantt;
          const output = `<div style="font-size:14px;padding: 0 10px;width:250px;line-height:20px;white-space:normal;">
              <p style="font-weight:bold;margin: 8px 0;">${task.text}</p>
              <p style="margin: 4px 0;">当前状态：${task.status === 1 ? "未开始" : task.status === 2 ? "已完成" : `${progress(start, end)}%`}</p>
          </div>`;
          return output;
      };
      gantt.attachEvent("onDataRender", () => {
        // 数据渲染完成后的逻辑
        this.showModel = true;
      });
    },
    leaveRow(i) {
     if (document.getElementsByClassName("gantt_task_row")[i]) {
      document.getElementsByClassName("gantt_task_row")[i].style.background = "";
     }
    },
    getCurRow(i) {
      const ganttRowListDom = document.getElementsByClassName("gantt_task_row");
      for (let index = 0; index < ganttRowListDom.length; index++) {
        if (index === i) {
          ganttRowListDom[index].style.background = "#ECF4FF";
          for (let j = 0; j < ganttRowListDom[index].children.length; j++) {
            if (ganttRowListDom[index].children[j].className.includes("weekend")) {
              ganttRowListDom[index].children[j].style.background = "transparent";
            }
          }
        } else {
          ganttRowListDom[index].style.background = "";
        }
      }
    },
    reload() {
      gantt.clearAll(); // 从甘特图中删除所有任务和其他元素（包括标记）
      // 数据时间 格式化
      gantt.templates.parse_date = (date) => new Date(date);
      console.log(this.ganttData);
      gantt.parse({
        data: JSON.parse(JSON.stringify(this.ganttData))
      }); // 数据解析
      this.marker = null;
      gantt.render(); // 呈现整个甘特图
    },
    getGanttData() {
      this.reload();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-select .el-input__inner {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  border: none;
}

/deep/ .el-input__suffix {
  display: flex;
  align-items: center;
  width: 18px;
}

.pull-left {
    float: left;
  }
  .pull-right {
    padding-top: 8px;
    float: right;
  }
  .topbar {
    height: 50px;
    border-bottom: 1px solid #eaebf0;
    padding: 0 10px;
    background: #fff;
    position: relative;
    .pull-center {
      position: absolute;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      line-height: 50px;
      font-size: 14px;
    }

    .back-btn {
      display: inline-block;
      vertical-align: middle;
      color: #aeaeae;
    }
  }

.gantt-box {
  width: 100%;
  // overflow-y: scroll;
  // overflow-x: scroll;
  height: 100%;
}

.main {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  .change-btn-box {
    position: absolute;
    display: flex;
    z-index: 10001;
    right: 20px;
    top: 16px;
    align-items: center;

    .todat-btn {
      width: 48px;
      height: 26px;
      background: #ffffff;
      border: 1px solid #e8ecf2;
      border-radius: 13px;
      text-align: center;
      line-height: 27px;
      font-size: 11px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
    }
    .todat-btn:hover{
      color: #3e90fe;
    }
    .todat-btn-active {
      color: #3e90fe;
    }

    .select-time {
      margin-left: 10px;
      margin-right: 20px;
      width: 48px;
      height: 26px;
      line-height: 26px;
      background: #ffffff;
      border: 1px solid #e8ecf2;
      border-radius: 13px;
      color: #636c78;
      padding: 5px;
      /deep/ .el-input--suffix{
        height: 26px;
        margin-top: -8px;
        padding: 0;
      }
      /deep/ .el-input__inner{
        height: 14px;
        min-height: 14px;
        line-height: 20px;
        padding: 0;
        width: 16px;
        display: inline-block;
        margin-left: 4px;
        margin-top: 8px;
      }
      /deep/ .el-input__suffix{
        height: 30px;
        // height: 20px;
      }
    }
    .select-time:hover /deep/ .el-input__inner{
      color: #3e90fe;
    }
  }
}

.table {
  // transition: all 0.5s;
  position: relative;
  background-color: royalblue;
  display: flex;
  .table-box{
    width: 100%;
    overflow: auto;
  }
  .drag {
    width: 2px;
    height: 100%;
    right: 0px;
    position: absolute;
    cursor: ew-resize;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: -10px;
    cursor: pointer;
    text-align: center;
    line-height: 18px;
    z-index: 1003;
    background: #fff;
    color: #a9b5c6;

    .iconfont {
      font-size: 10px;
    }
  }

  .roate-left {
    transform: rotate(180deg);
  }

  .roate-right {
    transform: rotate(360deg);
  }
}

.gantt-container {
  flex: 1;
  width: 100%;
  height: calc(100% - 50px);
}

/deep/ .gantt_tree_icon {
  &.gantt_folder_closed,
  &.gantt_file,
  &.gantt_folder_open {
    display: none;
  }
}

/* 今日高亮 */
/deep/ .gantt_task_scale {
  .gantt_scale_line {
    background-color: #f5f7fa;
    border-top: 1px solid transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    z-index: 1001;

    .year-cell {
      display: flex;
      justify-content: flex-start;
      padding-left: 15px;
      width: 400px !important;
      position: sticky !important;
      border-right: none;
      left: 0;
      top: 0;
    }

    .gantt_scale_cell {
      background-color: #f5f7fa;
      color: #333333;
    }

    .today {
      color: #0066ff;
      padding: 5px;
    }

    .task-cell {
      background: #fff !important;
      border-bottom: none !important;
    }
  }
}

/deep/ .gantt_task_row {
  border-bottom: none !important;
}

/deep/ .task-not-progress {
  background: rgba(62, 144, 254, 0.5) !important;
  border-radius: 21px;
  display: flex;
  border: none;
  align-items: center;
}

/deep/ .task-success {
  background: #46d48a !important;
  border-radius: 21px;
  display: flex;
  border: none;
  align-items: center;
}

/deep/ .task-progress {
  background: rgba(225, 163, 44, 0.5) !important;
  border-radius: 21px;
  display: flex;
  border: none;
  align-items: center;

  .jin-du {
    position: absolute;
    border-radius: 21px;
    width: 30%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffa32c;
  }
}

/deep/ .no-task {
  display: none;
}

/deep/ .gantt_task_content {
  .main-box {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: left;
    z-index: 10;
  }

  .dian {
    margin-left: 10px;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    background: #ffffff;
    border-radius: 4px;
    display: inline-block;
  }
  .task-name{
    display: inline-block;
    // min-width: 82px;
  }

  .tip {
    margin-left: 15px;
    width: 62px;
    height: 26px;
    background: #ffffff;
    border-radius: 13px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
  }
}

/deep/ br {
  line-height: 1.5;
}

/deep/ .gantt_scale_cell {
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .gantt_data_area {
  margin-top: 2px;
}

/deep/ .mark_line {
  width: 1px;
  height: 10000px !important;
  background: #3e90fe;
  top: 2px !important;
}

/deep/ .mark_line:before {
  width: 8px;
  height: 8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  content: " ";
  display: block;
  border-radius: 50%;
  background: #3e90fe;
}

/deep/ .gantt_task_cell:after {
  position: absolute;
  right: 0;
  width: 1px;
  height: 1000px !important;
  content: " ";
  display: block;
  background: #e6eaf0;
}

/deep/ .gantt_task_cell {
  border-right: none !important;
}

/deep/ .today .today-span {
  position: absolute;
  width: 24px;
  padding: 5px 5px;
    text-align: center;
  color: #fff;
  background: #3e90fe;
  border-radius: 12px;
  display: inline-block;
}

/deep/ .weekend {
  background: #fbfbfb;
  border-bottom: none !important;
}
/deep/ .vxe-filter-toolbar-wrapper{
  display: none;
}
/deep/ .vxe-table--header{
  height: 106px;
}
/deep/  .vxe-table.t--border .vxe-body--column{
  height: 48px!important;
}
/deep/ .gantt_task_row:hover{
  background:#ECF4FF!important;
}
/deep/.gantt_task_row:hover.gantt_task_content{
}
/deep/ .gantt_task_row:hover .weekend{
  background:transparent!important;
}
.pull-left {
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;

  .en-icon {
    color: #a9b5c6;
    cursor: pointer;
    vertical-align: middle;
  }

  .col-names {
    vertical-align: middle;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
    margin-right: 10px;
    font-weight: bold;
  }

  .header-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    background: linear-gradient(-30deg, #3E90FE, #5FA5FF);
    border-radius: 8px;
    margin-left: 6px;

    .en-icon {
      color: #fff
    }
  }

  .title-text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #1A1C1E;
  }
}
.gantt-box {

      /deep/ .nodata img{
        height: 86px;
        width: 90px;
      }

}
.back-icon {
    cursor: pointer;
    color: #A9B5C6;
  }
</style>
