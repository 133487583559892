<!--
 * @Author: zoujp
 * @Date: 2020-11-25 14:58:53
 * @LastEditTime: 2021-08-11 15:27:44
 * @LastEditors: zhulin
 * @Description: 共享管理-列表
 * @FilePath: \user\src\views\themeSet\share\index.vue
-->
<template>
  <div class="share-manage" v-en-loading="enloading">
    <div class="topbar">
      <div class="pull-left">
        <en-icon name="fanhui" size="small" class="back-btn" @click.native="back"></en-icon>
        <span style="margin-left:5px;">共享</span>
      </div>
      <div class="pull-right">
        <en-button icon="tianjia-anniutoubu" @click="add">
            添加
        </en-button>
      </div>
      <div class="pull-center">
      </div>
    </div>
    <div class="centbar">
      <en-table ref="shareTable" v-show="dataList.length>0" :data="dataList" :hover-row-config="{ dataMethod: buttonData, changeMethod: buttonClick }" height="100%">
        <en-table-column type="index" width="100px"></en-table-column>
        <en-table-column prop="name" label="共享组名称" width="300px"></en-table-column>
        <en-table-column prop="userList" label="共享组成员">
           <template v-slot="{ row }">
            <div v-if="row.userList.length <= 2">
              <div  style="display:flex">
                <div v-for="(item, index) in row.userList" :key="index" class="name-style">
                    <div class="name-sculpture" >{{item.refName.charAt(0) || ""}}</div>
                    <el-tooltip :content="item" placement="top" effect="light">
                      <div class="name-text" >{{item.refName}}</div>
                    </el-tooltip>
                </div>
              </div>
            </div>
            <div v-else>
              <el-tooltip :content="row.userList" placement="top" effect="light">
                <div class="sculpture-list">
                  <div v-for="(item, index) in row.userList" :key="index" class="sculpture-style"
                >
                    {{item.refName.charAt(0) || ""}}
                  </div>
                  <div v-if="row.userList.length > 3" class="sculpture-style-other">
                    +{{row.userList.length - 3}}
                  </div>
                </div>
              </el-tooltip>
            </div>
          </template>
        </en-table-column>
      </en-table>
      <div class="nodata" v-show="dataList.length===0"><en-result type="NodataAdd"></en-result></div>
    </div>
  </div>
</template>
<script>
import { request } from "en-js";
import { defaultListService } from "@/api";

export default {
  name: "shareManage",
  components: {
  },
  data() {
    return {
      that: this,
      enloading: false,
      templateId: this.$route.query.templateId, // 模板id
      dataList: []
    };
  },
  mounted() {
    this.getUserSharePower();
  },
  filters: {
    /**
     * @description: 格式化单元格数据
     * @param {*} item
     * @param {*} field
     * @return {*}
     */
    setField(item, field) {
      const res = [];
      (item[field] || []).forEach((itm) => {
        res.push(itm.refName || "");
      });
      return res.join(",");
    }
  },
  computed: {
  },
  methods: {
    /**
     * @description: 获取共享组列表数据集合
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async getUserSharePower() {
      const params = {
        templateId: this.templateId,
        pageSize: 9999,
        pageNo: 1
      };
      const list = await defaultListService.ptQueryUserSharePower(params);
      this.dataList = list || [];
    },
    /**
     * @description: 行按钮事件
     * @param {*} row
     * @param {*} index
     * @param {*} btnData
     * @return {*}
     */
    buttonClick({ row, index, btnData }) {
      console.log(index);
      switch (btnData.id) {
        case "btn-edit":// 编辑
          this.editRow(row, btnData.name);
          break;
        case "btn-start":// 启用
          this.stopRow(row, btnData.name, 0);
          break;
        case "btn-stop": // 停用
          this.stopRow(row, btnData.name, 1);
          break;
        case "btn-delete": // 删除
          this.deleteRow(row, btnData.name);
          break;
        default:
          break;
      }
    },
    /**
     * @description: 编辑
     * @param {*} row
     * @param {*} name
     * @return {*}
     */
    editRow(row) {
      this.$router.push({
        path: "/themeSet/shareForm",
        query: {
          templateId: this.templateId,
          id: row.id
        }
      });
    },
    /**
     * @description: 停用启用
     * @param {*} row
     * @param {*} name
     * @param {*} type
     * @return {*}
     */
    @request(true, "enloading")
    async stopRow(row, name, type) {
      await defaultListService.ptEnableUserSharePower({ templateId: this.templateId, id: row.id, enableFlag: type });
      this.$message(`${type === 0 ? "启用" : "停用"}成功`);
      setTimeout(() => {
        this.getUserSharePower();
      }, 500);
    },
    /**
     * @description: 删除
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    deleteRow(row) {
      this.$confirm("确定删除已选择的1条数据", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        defaultListService.ptDeleteUserSharePower({ templateId: this.templateId, id: row.id });
        this.$message("删除成功");
        setTimeout(() => {
          this.getUserSharePower();
        }, 500);
      });
    },
    /**
     * @description: 行按钮
     * @param {*} row
     * @return {*}
     */
    buttonData({ row }) {
      const resBtn = [];
      resBtn.push({
        icon: "bianji-liebiao",
        id: "btn-edit",
        name: "编辑",
        iconColor: "#26C393"
      });
      if (row.enableFlag === 0) {
        resBtn.push({
          icon: "jinyong-liebiao",
          id: "btn-stop",
          name: "停用",
          iconColor: "#A9A8A8"
        });
      } else {
        resBtn.push({
          icon: "qiyong-liebiao",
          id: "btn-start",
          name: "启用",
          iconColor: "#3e90fe"
        });
      }
      resBtn.push({
        icon: "shanchu-liebiao",
        id: "btn-delete",
        name: "删除",
        iconColor: "#F76B6B"
      });
      return resBtn;
    },
    /**
     * @description: 添加
     * @param {*}
     * @return {*}
     */
    add() {
      this.$router.push({
        path: "/themeSet/shareForm",
        query: {
          templateId: this.templateId
        }
      });
    },
    /**
     * @description: 返回
     * @param {*}
     * @return {*}
     */
    back() {
      this.$router.back(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.share-manage {
  background: #fff;
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .topbar {
    height: 50px;
    // border-bottom: 1px solid #eaebf0;
    padding: 0 10px;
    position: relative;
    .pull-left {
      padding-top: 15px;
      font-size: 14px;
      font-weight: bold;
    }
    .pull-right {
      padding-top: 8px;
    }
    .back-btn {
      display: inline-block;
      vertical-align: middle;
      color: #aeaeae;
    }
  }
  .centbar {
    height: calc(100% - 50px);
    .nodata{
      height: 100%;
      border-top: 1px solid #eaebf0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.sculpture-list{
  margin-left:5px;

  display: flex;
  align-items: center;
  .sculpture-style-other{
    margin-left:-5px;
    width: 24px;
    height: 24px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #B4BED8;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .sculpture-style{
    margin-left:-5px;
    width: 24px;
    height: 24px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.name-style{
  width: 78px;
  height: 24px;
  background: #F5F8FC;
  border: 1px solid #DCE5EC;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .name-sculpture{
    margin-left:4px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .name-text{
    margin-left:4px;
    width: 46px;
    height: 13px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1A1C1E;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
