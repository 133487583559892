<!--
 * @Author: zoujp
 * @Date: 2020-11-28 09:38:22
 * @LastEditTime: 2024-03-14 10:44:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\themeSet\viewGroup\index.vue
-->

<template>
  <div class="theme-set" v-en-loading="enloading">
    <div class="topbar">
      <div class="pull-left">
        <en-icon  class="back-icon" name="iconfanhuishangcengji" size="18px"
               @click.native="handleBackIconClick"></en-icon>
        <div class="header-icon">
          <en-icon name="iconmoban-yewujianmo1" size="16" color="#FCFCFC"></en-icon>
        </div>
        <span class="title-text">{{ templateName || '' }}</span>
        <themeViewComm :templateId="templateId" :viewId="viewId" :templateName="templateName"></themeViewComm>
        <en-select v-model="valueCondition" placeholder="请选择" :data="conditionOptions" @change="conditionChange" :props="conditionProps" size="medium"></en-select>
      </div>
      <div class="pull-right">
        <btnComm :btnList="batchBtnList" :templateId="templateId" @filterChange="filterChange" @compareChange="compareChange" @exportChange="exportChange"></btnComm>
      </div>
    </div>
    <div class="centbar">
      <div class="view-group" v-if="groupList && groupList.length">
        <dl class="scrum-mod" :style="listBorder(index)" v-for="(data,index) in groupList" :key="data.id">
          <dt class="scrum-head">
            <div class="col-tit">
              <span class="txts head-name" :style="headStyle(index)">{{ data.name || "未定义" }}</span>
              <span class="task-length">({{ data.dataList.length }})</span>
            </div>
          </dt>
          <dd class="scrum-cent">
            <div class="card-list" v-if="titleFields && titleFields.length">
              <el-popover width="290" trigger="hover" v-for="item in data.dataList" :key="item.id">
                <div class="card-mod" v-bind:class="{ finish: item.isChecked }"  slot="reference">
                  <div>
                    <el-checkbox v-model="item.isChecked" v-if="item.createId.length && item.createId[0].id === userInfo.userId" @change="finish(item)"></el-checkbox>
                    <div class="dot" v-show="item.refType===23&&item.readFlag==='000'">
                      <div class="in"></div>
                    </div>
                    <div v-html="getItemRow(item,'pop')" @click="readMsg(item)"></div>
                  </div>
                </div>
                <div class="card-popover">
                  <div class="col-handle" v-if="item.createId.length && item.createId[0].id === userInfo.userId && item.addType.toString() === '2'">
                    <span title="删除"><en-icon name="shanchu-kuaijiecaozuo" size="small" @click.native="del(item)">删除</en-icon></span>
                    <span :title="item.secretType.toString() === '1'?'取消私密':'私密'">
                      <en-icon name="simi"
                        size="22px"
                        :color="item.secretType.toString() === '2'?'#3e90fe':''"
                        @click.native="secret(data.id,item)">{{item.secretType.toString() === '1'?'取消私密':'私密'}}</en-icon>
                      </span>
                  </div>
                  <div class="col-handle" v-else-if="item.createId.length && item.createId[0].id === userInfo.userId && item.addType.toString() !== '2'">
                    <span :title="item.secretType.toString() === '1'?'取消私密':'私密'">
                      <en-icon name="simi" size="small" :color="item.secretType.toString() === '2'?'#3e90fe':''"
                      @click.native="secret(data.id,item)">{{item.secretType.toString() === '1'?'取消私密':'私密'}}</en-icon>
                    </span>
                  </div>
                  <div class="col-handle" v-else></div>
                  <div v-html="getItemRow(item, 'pop')" class="col-mods"></div>
                </div>
              </el-popover>
            </div>

          </dd>
          <div class="scrum-more">
            <div class="item-more " v-show="data.showMore" @click="itemMore(data)">加载更多 （{{ data.listSize - data.pageNo * dataListSize }}）</div>
          </div>
        </dl>
        <dl class="group-more" v-show="this.lastPageSize >= this.pageSize" @click="groupMore()">
           <en-icon
              name="tupian-youjiantou"
              color="#b5b5b6"
              size="small"
            >
            </en-icon>
        </dl>
      </div>
    </div>
    <filterComm ref="popFilter" :fieldList='titleFields' :searchParams='conditions' :setBaseData="setBaseData"  @filterSave="filterSave" @showBaseData="showBaseData"></filterComm>
    <alertCompare ref="popCompare" :templateId="templateId" :btnData="btnData"></alertCompare>
    <baseDataComm ref="popBaseData" :filterRowData="filterRowData" @saveBaseData="saveBaseData"></baseDataComm>
    <alertExport ref="popExport" :templateId="templateId" :exportData="exportData" ></alertExport>
    <businessSearchDialog
    @confirmSearch="filterSave"
    :search-field-list="colList"
    ref="searchDialog"
  >
  </businessSearchDialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { groupListService, defaultListService } from "@/api";

import { enService } from "@/api/en/index";
import btnComm from "../components/btnComm";
import shareComm from "../components/shareComm";
import viewComm from "../components/viewComm";

import filterComm from "../components/filterComm";
import baseDataComm from "../components/selectBaseData";
import alertCompare from "../components/alertCompare";
import alertExport from "../components/alertExport";
import * as utils from "../components/model/util/index";
import themeViewComm from "../components/themeViewComm";

export default {
  name: "viewGroup",
  components: {
    btnComm,
    // shareComm,
    // viewComm,
    themeViewComm,
    filterComm,
    baseDataComm,
    alertCompare,
    alertExport
  },
  data() {
    return {
      colorList: ["#47BAFF", "#3E90FE", "#45D489", "#FFA32B", "#A971F6", "#FD635B"],
      enloading: false,
      templateId: this.$route.query.templateId || "",
      viewId: this.$route.query.viewId || "",
      templateName: this.$route.query.templateName || "",
      shareUserId: "",
      checked: false,
      valueCondition: "",
      conditionOptions: [],
      conditionProps: { value: "id", label: "name" },
      configList: [],
      groupList: [],
      batchBtnList: [],
      singleBtnList: [],
      titleFields: [],
      conditions: [], // 请求条件
      supernatantFields: [],
      btnData: {},
      setBaseData: {}, // 类型为5时选择基础数据返回值回调填充筛选弹窗
      filterRowData: {}, // 过滤的某一行数据
      exportData: {},

      pageNo: 1, // 分组初始页码
      pageSize: 5, // 每页分组数
      dataListSize: 10, // 单个分组内单页数
      lastPageSize: 0, // 最好一页有多少条数据，用于是否显示分页
      iconMap: {
        1: "zttb1",
        2: "zttb2",
        3: "zttb3",
        4: "zttb4",
        5: "zttb5",
        6: "zttb6",
        7: "zttb7",
        8: "zttb8"
      }
    };
  },
  mounted() {
    this.queryGroupConfig();
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headStyle() {
      return (index) => ({
        background: this.colorList[index % 6]
        });
    },
    listBorder() {
      return (index) => ({
            "border-top": `5px solid ${this.colorList[index % 6]}`
        });
    }
  },
  methods: {
    /**
     * @description: 分组内分页
     * @param {*}
     * @return {*}
     */
    async itemMore(item) {
      console.log(item, this.dataListSize, "itemitemitemitemitemitem");
      const params = {
        templateId: this.templateId,
        pageNo: item.pageNo + 1,
        pageSize: this.dataListSize,
        isPreview: 1,
        conditions: JSON.stringify(this.conditions),
        shareUserId: this.shareUserId,
        groupField: this.valueCondition,
        viewId: this.viewId,
        groupFieldValue: item.id
      };
      const res = await groupListService.ptGroupDataList(params);
      this.groupList.forEach((a) => {
        if (a.id === item.id) {
          if (res && res?.length >= item.listSize) {
            console.log(item, "item847359834759");
            item.pageNo++;
            this.$set(a, "showMore", true);
          } else {
            this.$set(a, "showMore", false);
          }
          let chd = a.dataList;
          chd = chd.concat(res);
          this.$set(a, "dataList", chd);
        }
      });
      this.$forceUpdate();
    },
    /**
     * @description: 私密
     * @param {*} item
     * @return {*}
     */
    secret(gid, item) {
      const params = {
        templateId: this.templateId,
        id: item.id, // 业务建模数据ID
        refType: item.refType,
        secretType: item.secretType.toString() === "1" ? 2 : 1// Number1-私密、2-公开
      };
      this.$confirm(`确定要${item.secretType.toString() === "1" ? "取消私密" : "设为私密"}吗？`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.secretSubmit(gid, item, params);
        });
    },
    /**
     * @description: 私密提交
     * @param {*}
     * @return {*}
     */
    async secretSubmit(gid, item, params) {
      await defaultListService.ptSecret(params);
      this.$message("操作成功");
      this.groupList.forEach((a) => {
        if (a.id === gid) {
          a.dataList.forEach((c) => {
            if (c.id === item.id) {
              this.$set(c, "secretType", item.secretType.toString() === "1" ? 2 : 1);
            }
          });
        }
      });
      this.$forceUpdate();
    },
    /**
     * @description: 删除
     * @param {*} item
     * @return {*}
     */
    del(item) {
      const params = {
        templateId: this.templateId, // 主题模板ID
        id: item.id// 业务建模数据ID
      };
      this.$confirm("确定要删除吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.delSubmit(params);
        });
    },
    /**
     * @description: 删除提交
     * @param {*}
     * @return {*}
     */
    async delSubmit(params) {
      await defaultListService.ptOut(params);
      this.$message("操作成功");
      const list = [];
      this.groupList.forEach((a) => {
        const cle = cloneDeep(a);
        const child = [];
        a.dataList.forEach((c) => {
          const cleC = cloneDeep(c);
          if (cleC.id !== params.id) {
            child.push(cleC);
          }
        });
        cle.dataList = child;
        list.push(cle);
      });
      this.groupList = list;
      this.$forceUpdate();
    },
    /**
     * @description: 完成操作
     * @param {*}
     * @return {*}
     */
    finish(item) {
      let msg = "确定要标记为$$完成吗?";
      if (!item.isChecked) {
        msg = msg.replace("$$", "未");
      } else {
        msg = msg.replace("$$", "");
      }
      const params = {
        templateId: this.templateId,
        id: item.id,
        refType: item.refType,
        finishType: item.isChecked ? 1 : 2
      };
      this.$confirm(msg, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.finishSubmit(item, params);
        }).catch(() => {
          this.$set(item, "finishType", item.finishType);
          this.$set(item, "isChecked", !item.isChecked);
          this.$forceUpdate();
        });
    },
    /**
     * @description: 完成操作提交
     * @param {*}
     * @return {*}
     */
    async finishSubmit(item, params) {
      await defaultListService.ptFinish(params);
      this.$message("操作成功！");
      this.$set(item, "finishType", params.finishType);
      this.$set(item, "isChecked", item.isChecked);
      this.$forceUpdate();
    },
    /**
     * @description: 读取消息
     * @param {*}
     * @return {*}
     */
    async readMsg(item) {
      if (item.refType === 23 && item.readFlag === "000") { // 消息中心
        const params = {
          ids: item.id, isBatch: 1
        };
        await enService.readNotice(params);
        this.$set(item, "readFlag", "001");
        this.$forceUpdate();
      }
    },
    /**
     * @description: 分组加载更多
     * @param {*}
     * @return {*}
     */
    groupMore() {
      this.pageNo++;
      this.queryGroupList();
    },
    /**
     * @description: 分享回调
     * @param {*} share
     * @return {*}
     */
    saveCallback(share) {
      this.shareUserId = share.shareUserId;
      this.pageNo = 1;
      this.queryGroupList();
    },
    /**
     * @description:基础数据内容保存
     * @param {*}
     * @return {*}
     */
    saveBaseData(par) {
      this.setBaseData = par;
    },
    /**
     * @description: 选择基础数据
     * @param {*}
     * @return {*}
     */
    showBaseData(row) {
      this.$refs.popBaseData.visiable = true;
      this.filterRowData = row;
    },
    /**
     * @description: 过滤弹窗回调
     * @param {*} cons
     * @return {*}
     */
    filterSave(re) {
      this.conditions = JSON.parse(re.searchParams || JSON.stringify([]));
      this.pageNo = 1;
      this.queryGroupList();
    },
    /**
     * @description: 获取单条主题
     */
    getItemRow(data, type) {
      // console.log(data, this.supernatantFields, this.titleFields);
      const fieldData = type === "pop" ? this.supernatantFields : this.titleFields;
      const txt = [];
      fieldData.forEach((a) => {
        const isUser = a.field === "P2";
        let value = data[a.field];
        if (!value) {
          return true;
        }
        const colType = a.fieldType;
        let str = "";
        if (colType === 5 || colType === 15 || colType === 33) {
          if (value.length) {
            str = value.map((ele) => ele.name).join(",");
          }
        } else if (colType === 3 || colType === 4) {
          value = Number(value);
          if (a.numberFormat === 2) { // 百分比
            value *= 100;
          }
          if (a.permillage === "0") { // 千分
            value = utils.fmoney(value, Number(a.reserverDecimal));
          } else { // 正常数值
            value = value.toFixed(Number(a.reserverDecimal));
          }
          if (a.numberFormat === 2) { // 百分比
            value += "%";
          }
          str = value;
        } else if (colType === 7) {
          str = utils.getDateAfter(value, a.timeFormat);
        } else if (colType === 29) {
          if (value.provinceName) {
            str += value.provinceName;
          }
          if (value.cityName) {
            str += value.cityName;
          }
          if (value.districtName) {
            str += value.districtName;
          }
          if (value.address) {
            str += value.address;
          }
        } else {
          str = value;
        }

        let icons = "";
        if (a.icon) {
          // icons = `<en-icon name="${this.iconMap[a.icon]}" color="${a.contentColor}" size="small"></en-icon>`;
          icons = `<svg
            class="en-icon"
            aria-hidden="true"
            style="width:16px;height:16px;margin-right:3px;display: inline-block;vertical-align: middle;"
          >
            <use xlink:href="#icon${this.iconMap[a.icon]}"></use>
          </svg>`;
        }
        let dt = "";
        // 通过判断是否是人员，拼装dom, 原理查看 src/views/businessModel/task/components/viewsGroup.vue 页面下的  <el-popover>标签下的 slot="reference"
        if (isUser && !type) {
          if (data[a.field].length && data[a.field].length <= 3) {
            let userDome = "";
            data[a.field].forEach((el) => {
              userDome += `<div class="sculpture-style-two">${el.name[0]}</div>`;
            });
            dt = `<div class="item-box">${icons}<div class="sculpture-list-two">${userDome}</div></div>`;
          } else {
            const userDome = "";
            // data[a.field] && data[a.field].slice(0, 3).forEach((el) => {
            //   userDome += `<div class="sculpture-style">${el.name[0]}</div>`;
            // });
            dt = `<div class="item-box">${icons}<div class="sculpture-list">${userDome}<div class="sculpture-style-other">+${data[a.field]}</div></div></div>`;
          }
        } else {
          dt = `<dt class="col-names" style="line-height:22px;$$" >${icons}<span class="txts" style="display: inline-block;vertical-align: middle;">${str}</span></dt>`;
        }
        if (type !== "pop" && data.isChecked) {
          dt = dt.replace("$$", "color: #aeaeae;text-decoration: line-through;");
        } else {
          dt = dt.replace("$$", "");
        }
        if (dt) {
          txt.push(dt);
        }
        return txt;
      });
      return txt.join("");
    },
    /**
     * @description: 查询视图配置
     */
    @request(true, "enloading")
    async queryGroupConfig() {
      const pa = {
        templateId: this.templateId,
        isPreview: this.userInfo.isManager === "000" ? 0 : 1,
        viewId: this.viewId,
        shareUserId: this.shareUserId
      };
      const rsp = await groupListService.queryGroupConfig(pa);
      // const arr = rsp || {};
      // this.configList = arr;
      this.batchBtnList = rsp.batchBtnList || [];
      this.singleBtnList = rsp.singleBtnList || [];
      // 平铺字段
      this.titleFields = rsp.titleFields || [];
      // 浮窗字段
      this.supernatantFields = rsp.supernatantFields || [];
      const groupFields = [];
      const fields = (rsp.groupField || "").split(",");
      const names = (rsp.groupFieldName || "").split(",");
      fields.forEach((item, index) => {
        groupFields.push({ id: item, name: names[index] });
      });
      this.conditionOptions = groupFields;
      this.valueCondition = fields[0];
      this.queryGroupList();
    },
    /**
     * @description: 查询分组视图具体数据
     */
    @request(true, "enloading")
    async queryGroupList() {
      const pa = {
        templateId: this.templateId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        dataListSize: this.dataListSize,
        isPreview: this.userInfo.isSysManager === "000" ? 0 : 1,
        conditions: JSON.stringify(this.conditions),
        shareUserId: this.shareUserId,
        groupField: this.valueCondition,
        viewId: this.viewId
      };
      const rsp = await groupListService.queryGroupList(pa);
      const arr = rsp || [];
      if (this.pageNo > 1) {
        this.groupList = this.groupList.concat(arr);
      } else {
        this.groupList = arr;
      }
      this.groupList.forEach((a) => {
        a.dataList.forEach((c) => {
          // 设置是否选中
          if (c.finishType.toString() === "1") {
            this.$set(c, "isChecked", true);
          } else {
            this.$set(c, "isChecked", false);
          }
          // c.pageNo = 1;
          // c.listSize = 10;

          console.log(c, "11111112222222222");
        });
        this.$set(a, "pageNo", 1);
          this.$set(a, "listSize", 1);
        // 设置分页信息
        this.$set(a, "pageNo", 1);
        if (a.dataList.length >= this.dataListSize) {
          this.$set(a, "showMore", true);
        } else {
          this.$set(a, "showMore", false);
        }
      });
      console.log(this.groupList, "this.groupListthis.groupList");
    },
    /**
     * @description: 切换分组
     * @param {*}
     * @return {*}
     */
    conditionChange() {
      this.pageNo = 1;
      this.queryGroupList();
    },
    /**
     * @description: 过滤按钮回调
     * @param {*}
     * @return {*}
     */
    filterChange() {
      // this.$refs.popFilter.visiable = true;
      this.$refs.searchDialog.open();
    },
    /**
     * @description: 对比按钮回调
     * @param {*}
     * @return {*}
     */
    compareChange(btnData) {
      this.$refs.popCompare.visiable = true;
      this.btnData = btnData;
    },
    /**
     * @description: 导出按钮回调
     * @param {*}
     * @return {*}
     */
    exportChange() {
      this.$refs.popExport.visible = true;
      const {
        templateId, shareUserId, conditions, titleFields
      } = this;
      this.exportData = {
        templateId, shareUserId, filterFields: conditions, showList: titleFields
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-set {
  background: #fff;
  // .pull-left {
  //   float: left;
  //    & /deep/ .el-input{
  //     font-size: 12px;
  //   }
  // }
  .pull-right {
    float: right;
  }
  .topbar {
    height: 50px;
    border-bottom: 1px solid #eaebf0;
    padding: 0 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pull-center {
      position: absolute;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      line-height: 50px;
      font-size: 14px;
    }
    .pull-left {

      display: flex;
      justify-content: left;
      align-items: center;
      .col-names {
        vertical-align: middle;
        font-size: 14px;

        color: rgba(51, 51, 51, 1);
        line-height: 50px;
      }
    }
    .pull-right {
      padding-top: 8px;
    }
    .back-btn {
      display: inline-block;
      vertical-align: middle;
      color: #2c3e50;
    }
  }
  .centbar {
    // padding-top: 10px;
    height: calc(100% - 50px);
  }
}
.view-group {
  height: 100%;
  overflow: hidden;
  display: flex;
  background: #fff;
  padding: 10px 0;
  overflow-x: auto;
  .scrum-mod {
    vertical-align: top;
    width: 296px;
    background: #F5F7FA;
    margin: 0 8px 0 0;
    border-radius: 6px;
    height: 100%;
    cursor: default;
    flex-shrink: 0;
    padding: 10px;
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
  }
  .scrum-head {
    zoom: 1;
    padding: 0 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-name{
      width: 78px;
      height: 26px;
      border-radius: 13px;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      padding: 5px 10px;
    }
    .task-length{
      padding-left: 10px;
      font-size: 14px;
      color: #91A1B7;
    }
  }
  .scrum-cent {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
    margin: 0;
    font-size: 12px;
    margin-top: 20px;
  }
  .scrum-more{
    font-size: 12px;
  }
  .card-list {
    margin: 0 10px;
    // padding: 10px;
    // overflow: hidden;
  }
  .scrum-cent::-webkit-scrollbar {
              display: none;
            }
  .scrum-cent:hover{
    overflow-y: auto
  }

  .card-mod {
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #DCE5EC;
    border-radius: 6px;
    padding: 10px;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    // .col-names {
    //   line-height: 30px;
    // }
    .el-checkbox {
      margin-right: 10px;
    }
    .dot{
      width:10px;
      height: 10px;
      padding-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .in{
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #ff0000;
      }
    }
  }
  .finish{
    background:#fcfcfc ;
  }
  .group-more{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;
    background: #fff;
    cursor: pointer;
    margin: 0 8px 0 0;
    border-radius: 5px;
    padding: 0 5px;
  }
  .item-more{
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #92a2b8;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8ecf2;
    cursor: pointer;
  }
}

.view-group:hover{
  overflow-x: auto;
}
.card-popover{
  font-size: 12px;
  padding-right:40px;
  padding:10px 30px 10px 10px ;
  position: relative;
  dt{
    // line-height: 30px;
    margin-bottom:5px;
    .en-icon{
      display: inline-block;
      vertical-align: middle;
    }
    .txts{
      display: inline-block;
      vertical-align: middle;
    }
  }
  .col-handle {
    text-align: right;
    right:0;
    top:0;
    position: absolute;
    .en-icon {
      cursor: pointer;
      // display: inline-block;
      // vertical-align: middle;
      &.cur {
        color: #409eff;
      }
    }
  }
}
</style>
<style lang="scss">
.item-box{
  display: flex;
  align-items: center;
}
.sculpture-list-two{
    display: flex;
    align-items: center;
}
.sculpture-style-two{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.sculpture-list{
  display: flex;
  margin-left: 5px;
  align-items: center;
  .sculpture-style-other{
    margin-left:-5px;
    width: 20px;
    height: 20px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #B4BED8;
    border-radius: 50%;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .sculpture-style{
    margin-left:-5px;
    width: 20px;
    height: 20px;
    line-height: 1.5;
    border: 2px solid #F2F4F7;
    text-align: center;
    background: #3E90FE;
    border-radius: 50%;
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.pull-left {

  display: flex;
  justify-content: left;
  align-items: center;

  .col-names {
    vertical-align: middle;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
    margin-right: 10px;
    font-weight: bold;
  }

  .header-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    background: linear-gradient(-30deg, #3E90FE, #5FA5FF);
    border-radius: 8px;
    margin-left: 6px;

    .en-icon {
      color: #fff
    }
  }

  .title-text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #1A1C1E;
  }
}
</style>
