var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enloading,
          expression: "enloading",
        },
      ],
      staticClass: "share-manage",
    },
    [
      _c("div", { staticClass: "topbar" }, [
        _c(
          "div",
          { staticClass: "pull-left" },
          [
            _c("en-icon", {
              staticClass: "back-btn",
              attrs: { name: "fanhui", size: "small" },
              nativeOn: {
                click: function ($event) {
                  return _vm.back.apply(null, arguments)
                },
              },
            }),
            _c("span", { staticStyle: { "margin-left": "5px" } }, [
              _vm._v("共享"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c(
              "en-button",
              { attrs: { icon: "tianjia-anniutoubu" }, on: { click: _vm.add } },
              [_vm._v(" 添加 ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "pull-center" }),
      ]),
      _c(
        "div",
        { staticClass: "centbar" },
        [
          _c(
            "en-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dataList.length > 0,
                  expression: "dataList.length>0",
                },
              ],
              ref: "shareTable",
              attrs: {
                data: _vm.dataList,
                "hover-row-config": {
                  dataMethod: _vm.buttonData,
                  changeMethod: _vm.buttonClick,
                },
                height: "100%",
              },
            },
            [
              _c("en-table-column", {
                attrs: { type: "index", width: "100px" },
              }),
              _c("en-table-column", {
                attrs: { prop: "name", label: "共享组名称", width: "300px" },
              }),
              _c("en-table-column", {
                attrs: { prop: "userList", label: "共享组成员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.userList.length <= 2
                          ? _c("div", [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                _vm._l(row.userList, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "name-style" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "name-sculpture" },
                                        [
                                          _vm._v(
                                            _vm._s(item.refName.charAt(0) || "")
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: item,
                                            placement: "top",
                                            effect: "light",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "name-text" },
                                            [_vm._v(_vm._s(item.refName))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: row.userList,
                                      placement: "top",
                                      effect: "light",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "sculpture-list" },
                                      [
                                        _vm._l(
                                          row.userList,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "sculpture-style",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.refName.charAt(0) ||
                                                        ""
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        row.userList.length > 3
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "sculpture-style-other",
                                              },
                                              [
                                                _vm._v(
                                                  " +" +
                                                    _vm._s(
                                                      row.userList.length - 3
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dataList.length === 0,
                  expression: "dataList.length===0",
                },
              ],
              staticClass: "nodata",
            },
            [_c("en-result", { attrs: { type: "NodataAdd" } })],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }