<!--
 * @Author: zoujp
 * @Date: 2020-11-25 16:32:15
 * @LastEditTime: 2021-08-19 09:47:25
 * @LastEditors: wuqi
 * @Description: 共享组新增表单
 * @FilePath: \user\src\views\themeSet\share\addForm.vue
-->
<template>
  <div class="share-form" v-en-loading="enloading">
    <div class="topbar">
      <div class="pull-left">
        <en-icon name="fanhui" size="small" class="back-btn" @click.native="back"></en-icon>
        <span style="margin-left:5px;">{{ editId ? '编辑':'新增'}}共享组</span>
      </div>
      <div class="pull-right">
         <en-button icon="tianjia-anniutoubu" @click="save">
            保存
        </en-button>
      </div>
      <div class="pull-center">
      </div>
    </div>
    <div class="centbar">
      <div class="form-wrap">
        <div class="mod">
          <div class="mod-title">基本信息</div>
          <el-form ref="shareForm"
            :model="form"
            label-position="left"
            label-width="80px"
          >
            <el-form-item label="共享组名称" prop="name" style="width:600px;">
              <el-input maxlength="20" placeholder="请输入" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="共享组成员" prop="userList" style="width:600px;">
              <en-select-data v-model="form.userList" :config="transferSet"></en-select-data>
            </el-form-item>
          </el-form>
        </div>
        <div class="mod">
          <div class="mod-title">共享权限
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">共享类型 <br/>全部信息：共享全部字段 <br/>人员部门信息：部门、人员</div>
              <i  class="iconfont icontishi iconStyle"></i>
            </el-tooltip>
             </div>
            <en-table ref="powerTable"
              :data="powerList"
              :hover-row-config="{ dataMethod: buttonData, changeMethod: buttonClick }"
              height="100%"
            >
              <en-table-column type="index" width="60px"></en-table-column>
              <en-table-column title="数据范围">
                <template #edit="{ row }">
                  <en-select class="mod" data-mode="data" v-model="row.refId" placeholder="请选择" :data="sourceList"  :props="{value:'id',label:'refName'}"></en-select>
                </template>
              </en-table-column>
              <en-table-column title="共享类型">
                 <template #edit="{ row }">
                  <en-select class="mod" v-model="row.shareTypeId" placeholder="请选择" :data="shareTypeList"  :props="{value:'id',label:'name'}"></en-select>
                </template>
              </en-table-column>
              <template #headEnd>
                <div class="head-add">
                  <en-icon  name="tianjia-liebiao" size="small" color="#3E90FE" @click.native="addRow"></en-icon>
                </div>
              </template>
            </en-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { defaultListService, balanceService } from "@/api";
import enSelectData from "@/components/en-select-data";

export default {
  name: "shareForm",
  components: {
    enSelectData
  },
  data() {
    return {
      enloading: false,
      templateId: this.$route.query.templateId, // 模板id
      editId: this.$route.query.id, // 编辑id
      dataMap: {},
      powerList: [],
      sourceList: [],
      shareTypeList: [],
      transferSet: {
        isMulti: true, // 是否多选
        placeholder: "请选择",
        pageSize: 10,
        selectMode: "tree",
        dependData: {
          requestService: balanceService, // 引入服务名称
          requestFn: "queryDeptUserTree", // 服务中方法
          requestParams: { id: 0 } // 请求参数
        },
        defaultProps: {
          label: "name",
          value: "id",
          isLeaf: "leaf"
        }
      },
      form: {
        name: "",
        userList: []
      }
    };
  },
  mounted() {
    this.querySource();
    this.queryShareType();
    if (this.editId) {
      this.getUserSharePower();
    }
  },
  computed: {
  },
  methods: {
    /**
     * @description: 新增一行
     * @param {*}
     * @return {*}
     */
    addRow() {
      this.powerList.push({
        refId: "",
        shareTypeId: ""
      });
    },
    /**
     * @description: 设置行删除按钮
     * @param {*}
     * @return {*}
     */
    buttonData() {
      return [{
        icon: "shanchu-liebiao",
        id: "btn-delete",
        name: "删除",
        iconColor: "#F76B6B"
      }];
    },
    /**
     * @description: 行按钮操作回调
     * @param {*} row
     * @param {*} index
     * @param {*} btnData
     * @return {*}
     */
    buttonClick({ row, index, btnData }) {
      console.log(index);
      switch (btnData.id) {
        case "btn-delete": // 删除
          this.deleteRow(row, index);
          break;
        default:
          break;
      }
    },
    /**
     * @description: 删除一行权限
     * @param {*} row
     * @param {*} index
     * @return {*}
     */
    deleteRow(row, index) {
      this.powerList.splice(index, 1);
    },
    /**
     * @description: 查询数据源
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async querySource() {
      const params = { templateId: this.templateId };
      const res = await defaultListService.ptQuerySource(params);
      this.sourceList = res;
    },
    /**
     * @description: 查询分享类型
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async queryShareType() {
      const params = { templateId: this.templateId };
      const res = await defaultListService.ptQueryShareType(params);
      this.shareTypeList = res;
    },
    /**
     * @description: 获取共享组列表数据集合
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async getUserSharePower() {
      const params = {
        templateId: this.templateId,
        pageSize: 9999,
        pageNo: 1
      };
      const res = await defaultListService.ptQueryUserSharePower(params);
      res.forEach((item) => {
        if (item.id.toString() === this.editId.toString()) {
          this.dataMap = item;
          this.form.name = item.name;
          this.form.userList = (() => {
            const re = [];
            item.userList.forEach((b) => {
              b.id = b.refId;
              b.name = b.refName;
              b.type = b.refType === "2" ? "003" : "001";
              re.push(b);
            });
            return re;
          })();
          this.powerList = (() => {
            const re = [];
            item.shareDataRanges.forEach((b) => {
              const cle = cloneDeep(b);
              cle.refId = b;
              re.push(cle);
            });
            return re;
          })();
        }
      });
    },
    /**
     * @description: 返回
     * @param {*}
     * @return {*}
     */
    back() {
      this.$router.back(-1);
    },
    /**
     * @description: 保存
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    save() {
      const that = this;
      const sourcelist = [];
      let has = false;
      const pa = {
        id: this.editId,
        templateId: this.templateId,
        name: this.form.name,
        // 权限人员
        userList: (() => {
          const re = [];
          this.form.userList.forEach((item) => {
            re.push({
              refId: item.refId || item.id,
              refName: item.displayName || item.name || item.refName,
              refType: item.type === "003" ? "2" : "1",
              url: item.userLogo || ""
            });
          });
          return re;
        })(),
        // 权限范围
        shareDataRanges: (() => {
          const re = [];
          for (let i = 0, len = that.powerList.length; i < len; i++) {
            const m = that.powerList[i];
            if (!m.refId.id) {
              has = true;
              break;
            }
            const obj = {
              refId: m.refId.id,
              refName: m.refId.refName,
              refType: m.refId.refType,
              shareTypeId: m.shareTypeId
            };
            sourcelist.push(obj.refId);
            re.push(obj);
          }
          return re;
        })()
      };

      if (!pa.name) {
        this.$message("请输入共享组名称");
        return;
      } if (!pa.userList.length) {
        this.$message("请选择共享组成员");
        return;
      } if (has) {
        this.$message("请选择数据源");
        return;
      } if ((new Set(sourcelist)).size !== sourcelist.length) {
        this.$message("数据源只能设置一次");
        return;
      } if (!pa.shareDataRanges.length) {
        this.$message("请添加共享组权限");
        return;
      }
      pa.userList = JSON.stringify(pa.userList);
      pa.shareDataRanges = JSON.stringify(pa.shareDataRanges);
      defaultListService.ptSaveUserSharePower(pa);
      this.$message("保存成功");
      setTimeout(() => {
        this.$router.back(-1);
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.share-form {
  background: #fff;
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .topbar {
    height: 50px;
    border-bottom: 1px solid #eaebf0;
    padding: 0 10px;
    position: relative;
    .pull-left {
      padding-top: 15px;
      font-size: 14px;
      font-weight: bold;
    }
    .pull-right {
      padding-top: 8px;
    }
    .back-btn {
      display: inline-block;
      vertical-align: middle;
      color: #aeaeae;
    }
  }
  .centbar {
    height: calc(100% - 50px);
    .form-wrap{
      padding: 20px;
      .mod-title{
        font-size: 12px;
        color: #1A1C1E;
        font-weight: bold;
        padding: 10px 0;
      }
      .head-add{
        width: 49px;
        height: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.iconStyle{
  cursor: pointer;
  color:#F7BF27;
}
</style>
