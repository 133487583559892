var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enloading,
          expression: "enloading",
        },
      ],
      staticClass: "theme-set",
    },
    [
      _c("div", { staticClass: "topbar" }, [
        _c(
          "div",
          { staticClass: "pull-left" },
          [
            _c("en-icon", {
              staticClass: "back-icon",
              attrs: { name: "iconfanhuishangcengji", size: "18px" },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleBackIconClick.apply(null, arguments)
                },
              },
            }),
            _c(
              "div",
              { staticClass: "header-icon" },
              [
                _c("en-icon", {
                  attrs: {
                    name: "iconmoban-yewujianmo1",
                    size: "16",
                    color: "#FCFCFC",
                  },
                }),
              ],
              1
            ),
            _c("span", { staticClass: "title-text" }, [
              _vm._v(_vm._s(_vm.templateName || "")),
            ]),
            _c("themeViewComm", {
              attrs: {
                templateId: _vm.templateId,
                viewId: _vm.viewId,
                templateName: _vm.templateName,
              },
            }),
            _c("en-select", {
              attrs: {
                placeholder: "请选择",
                data: _vm.conditionOptions,
                props: _vm.conditionProps,
                size: "medium",
              },
              on: { change: _vm.conditionChange },
              model: {
                value: _vm.valueCondition,
                callback: function ($$v) {
                  _vm.valueCondition = $$v
                },
                expression: "valueCondition",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c("btnComm", {
              attrs: { btnList: _vm.batchBtnList, templateId: _vm.templateId },
              on: {
                filterChange: _vm.filterChange,
                compareChange: _vm.compareChange,
                exportChange: _vm.exportChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "centbar" }, [
        _vm.groupList && _vm.groupList.length
          ? _c(
              "div",
              { staticClass: "view-group" },
              [
                _vm._l(_vm.groupList, function (data, index) {
                  return _c(
                    "dl",
                    {
                      key: data.id,
                      staticClass: "scrum-mod",
                      style: _vm.listBorder(index),
                    },
                    [
                      _c("dt", { staticClass: "scrum-head" }, [
                        _c("div", { staticClass: "col-tit" }, [
                          _c(
                            "span",
                            {
                              staticClass: "txts head-name",
                              style: _vm.headStyle(index),
                            },
                            [_vm._v(_vm._s(data.name || "未定义"))]
                          ),
                          _c("span", { staticClass: "task-length" }, [
                            _vm._v("(" + _vm._s(data.dataList.length) + ")"),
                          ]),
                        ]),
                      ]),
                      _c("dd", { staticClass: "scrum-cent" }, [
                        _vm.titleFields && _vm.titleFields.length
                          ? _c(
                              "div",
                              { staticClass: "card-list" },
                              _vm._l(data.dataList, function (item) {
                                return _c(
                                  "el-popover",
                                  {
                                    key: item.id,
                                    attrs: { width: "290", trigger: "hover" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "card-mod",
                                        class: { finish: item.isChecked },
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            item.createId.length &&
                                            item.createId[0].id ===
                                              _vm.userInfo.userId
                                              ? _c("el-checkbox", {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.finish(item)
                                                    },
                                                  },
                                                  model: {
                                                    value: item.isChecked,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isChecked",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isChecked",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.refType === 23 &&
                                                      item.readFlag === "000",
                                                    expression:
                                                      "item.refType===23&&item.readFlag==='000'",
                                                  },
                                                ],
                                                staticClass: "dot",
                                              },
                                              [_c("div", { staticClass: "in" })]
                                            ),
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.getItemRow(item, "pop")
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.readMsg(item)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "card-popover" }, [
                                      item.createId.length &&
                                      item.createId[0].id ===
                                        _vm.userInfo.userId &&
                                      item.addType.toString() === "2"
                                        ? _c(
                                            "div",
                                            { staticClass: "col-handle" },
                                            [
                                              _c(
                                                "span",
                                                { attrs: { title: "删除" } },
                                                [
                                                  _c(
                                                    "en-icon",
                                                    {
                                                      attrs: {
                                                        name: "shanchu-kuaijiecaozuo",
                                                        size: "small",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.del(item)
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title:
                                                      item.secretType.toString() ===
                                                      "1"
                                                        ? "取消私密"
                                                        : "私密",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "en-icon",
                                                    {
                                                      attrs: {
                                                        name: "simi",
                                                        size: "22px",
                                                        color:
                                                          item.secretType.toString() ===
                                                          "2"
                                                            ? "#3e90fe"
                                                            : "",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.secret(
                                                            data.id,
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.secretType.toString() ===
                                                            "1"
                                                            ? "取消私密"
                                                            : "私密"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : item.createId.length &&
                                          item.createId[0].id ===
                                            _vm.userInfo.userId &&
                                          item.addType.toString() !== "2"
                                        ? _c(
                                            "div",
                                            { staticClass: "col-handle" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title:
                                                      item.secretType.toString() ===
                                                      "1"
                                                        ? "取消私密"
                                                        : "私密",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "en-icon",
                                                    {
                                                      attrs: {
                                                        name: "simi",
                                                        size: "small",
                                                        color:
                                                          item.secretType.toString() ===
                                                          "2"
                                                            ? "#3e90fe"
                                                            : "",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.secret(
                                                            data.id,
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.secretType.toString() ===
                                                            "1"
                                                            ? "取消私密"
                                                            : "私密"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _c("div", {
                                            staticClass: "col-handle",
                                          }),
                                      _c("div", {
                                        staticClass: "col-mods",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getItemRow(item, "pop")
                                          ),
                                        },
                                      }),
                                    ]),
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "scrum-more" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: data.showMore,
                                expression: "data.showMore",
                              },
                            ],
                            staticClass: "item-more",
                            on: {
                              click: function ($event) {
                                return _vm.itemMore(data)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "加载更多 （" +
                                _vm._s(
                                  data.listSize - data.pageNo * _vm.dataListSize
                                ) +
                                "）"
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                _c(
                  "dl",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.lastPageSize >= this.pageSize,
                        expression: "this.lastPageSize >= this.pageSize",
                      },
                    ],
                    staticClass: "group-more",
                    on: {
                      click: function ($event) {
                        return _vm.groupMore()
                      },
                    },
                  },
                  [
                    _c("en-icon", {
                      attrs: {
                        name: "tupian-youjiantou",
                        color: "#b5b5b6",
                        size: "small",
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c("filterComm", {
        ref: "popFilter",
        attrs: {
          fieldList: _vm.titleFields,
          searchParams: _vm.conditions,
          setBaseData: _vm.setBaseData,
        },
        on: { filterSave: _vm.filterSave, showBaseData: _vm.showBaseData },
      }),
      _c("alertCompare", {
        ref: "popCompare",
        attrs: { templateId: _vm.templateId, btnData: _vm.btnData },
      }),
      _c("baseDataComm", {
        ref: "popBaseData",
        attrs: { filterRowData: _vm.filterRowData },
        on: { saveBaseData: _vm.saveBaseData },
      }),
      _c("alertExport", {
        ref: "popExport",
        attrs: { templateId: _vm.templateId, exportData: _vm.exportData },
      }),
      _c("businessSearchDialog", {
        ref: "searchDialog",
        attrs: { "search-field-list": _vm.colList },
        on: { confirmSearch: _vm.filterSave },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }