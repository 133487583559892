var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enloading,
          expression: "enloading",
        },
      ],
      staticClass: "share-form",
    },
    [
      _c("div", { staticClass: "topbar" }, [
        _c(
          "div",
          { staticClass: "pull-left" },
          [
            _c("en-icon", {
              staticClass: "back-btn",
              attrs: { name: "fanhui", size: "small" },
              nativeOn: {
                click: function ($event) {
                  return _vm.back.apply(null, arguments)
                },
              },
            }),
            _c("span", { staticStyle: { "margin-left": "5px" } }, [
              _vm._v(_vm._s(_vm.editId ? "编辑" : "新增") + "共享组"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pull-right" },
          [
            _c(
              "en-button",
              {
                attrs: { icon: "tianjia-anniutoubu" },
                on: { click: _vm.save },
              },
              [_vm._v(" 保存 ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "pull-center" }),
      ]),
      _c("div", { staticClass: "centbar" }, [
        _c("div", { staticClass: "form-wrap" }, [
          _c(
            "div",
            { staticClass: "mod" },
            [
              _c("div", { staticClass: "mod-title" }, [_vm._v("基本信息")]),
              _c(
                "el-form",
                {
                  ref: "shareForm",
                  attrs: {
                    model: _vm.form,
                    "label-position": "left",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { label: "共享组名称", prop: "name" },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20", placeholder: "请输入" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "600px" },
                      attrs: { label: "共享组成员", prop: "userList" },
                    },
                    [
                      _c("en-select-data", {
                        attrs: { config: _vm.transferSet },
                        model: {
                          value: _vm.form.userList,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userList", $$v)
                          },
                          expression: "form.userList",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mod" },
            [
              _c(
                "div",
                { staticClass: "mod-title" },
                [
                  _vm._v("共享权限 "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "bottom" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v("共享类型 "),
                          _c("br"),
                          _vm._v("全部信息：共享全部字段 "),
                          _c("br"),
                          _vm._v("人员部门信息：部门、人员"),
                        ]
                      ),
                      _c("i", { staticClass: "iconfont icontishi iconStyle" }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "en-table",
                {
                  ref: "powerTable",
                  attrs: {
                    data: _vm.powerList,
                    "hover-row-config": {
                      dataMethod: _vm.buttonData,
                      changeMethod: _vm.buttonClick,
                    },
                    height: "100%",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "headEnd",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "head-add" },
                            [
                              _c("en-icon", {
                                attrs: {
                                  name: "tianjia-liebiao",
                                  size: "small",
                                  color: "#3E90FE",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.addRow.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("en-table-column", {
                    attrs: { type: "index", width: "60px" },
                  }),
                  _c("en-table-column", {
                    attrs: { title: "数据范围" },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function ({ row }) {
                          return [
                            _c("en-select", {
                              staticClass: "mod",
                              attrs: {
                                "data-mode": "data",
                                placeholder: "请选择",
                                data: _vm.sourceList,
                                props: { value: "id", label: "refName" },
                              },
                              model: {
                                value: row.refId,
                                callback: function ($$v) {
                                  _vm.$set(row, "refId", $$v)
                                },
                                expression: "row.refId",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("en-table-column", {
                    attrs: { title: "共享类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function ({ row }) {
                          return [
                            _c("en-select", {
                              staticClass: "mod",
                              attrs: {
                                placeholder: "请选择",
                                data: _vm.shareTypeList,
                                props: { value: "id", label: "name" },
                              },
                              model: {
                                value: row.shareTypeId,
                                callback: function ($$v) {
                                  _vm.$set(row, "shareTypeId", $$v)
                                },
                                expression: "row.shareTypeId",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }