<!--
 * @Author: zoujp
 * @Date: 2020-11-26 17:31:11
 * @LastEditTime: 2021-04-28 10:41:14
 * @LastEditors: Please set LastEditors
 * @Description: 对比视图
 * @FilePath: \user\src\views\themeSet\compare\index.vue
-->
<template>
  <div class="theme-set" v-en-loading="enloading">
    <div class="topbar">
      <div class="pull-left">
        <div class="view-select" @click="back"><en-icon name="fanhui" size="small" class="back-btn"></en-icon><span class="tit">对比视图</span></div>
      </div>
      <div class="pull-right">
        <div class="mods">
          <div class="btn-mod" @click="compare">
            <en-icon name="duibi" size="small" color="#D69E79"></en-icon>
            <div class="txts">对比</div>
          </div>
          <div class="btn-mod" @click="filter">
            <en-icon name="iconshaixuan" size="small" color="#9367EB"></en-icon>
            <div class="txts">筛选</div>
          </div>
        </div>
      </div>
    </div>
    <div class="centbar"></div>
    <alertCompare ref="popCompare" :templateId="templateId" :btnData="btnData" :type="type" :initIds="id" @submitCompare="submitCompare"></alertCompare>

    <filterComm
      ref="popFilter"
      :fieldList="colList"
      :compList="compList"
      :searchParams="conditions"
      :compParams="conditionsComp"
      :setBaseData="setBaseData"
      :showCompare="showCompare"
      @filterSave="filterSave"
      @showBaseData="showBaseData"
    ></filterComm>

    <baseDataComm ref="popBaseData" :filterRowData="filterRowData" @saveBaseData="saveBaseData"></baseDataComm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { request } from "en-js";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { defaultListService } from "@/api";
import alertCompare from "../components/alertCompare";
import filterComm from "../components/filterComm";
import baseDataComm from "../components/selectBaseData";
// eslint-disable

export default {
  name: "viewCompare",
  components: { alertCompare, filterComm, baseDataComm },
  data() {
    return {
      enloading: false,
      type: "2", // 打开对比弹窗类型
      templateId: this.$route.query.templateId, // 模板id
      id: this.$route.query.id, // 对比人的id
      timeField: this.$route.query.timeField, // 对比字段
      btnData: { dataRange: [{ field: "" }] },
      colList: [], // 筛选字段
      compList: [], // 对比字段
      setBaseData: {}, // 类型为5时选择基础数据返回值回调填充筛选弹窗
      conditions: [], // 过滤条件
      conditionsComp: [], // 对比条件

      compUserIds: "", // 对比
      compDataList: [],
      compTime: [],
      filterRowData: {}, // 过滤的某一行数据
      showCompare: true // 过滤弹窗是否显示对比tab
    };
  },
  mounted() {
    this.getCompareUser();
    this.getDefaultCompare();
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    /**
     * @description: 设置默认条件
     * @param {*} type 1对比 2筛选
     * @param {*} cons
     * @return {*}
     */
    async setDefaults(type, cons) {
      const pa = { funcType: type, uniqueId: this.templateId, defaults: JSON.stringify(cons) };
      await defaultListService.setDefaults(pa);
    },
    /**
     * @description: 过滤弹窗回调
     * @param {*} cons
     * @return {*}
     */
    filterSave(re) {
      this.conditions = re.filterData || [];
      this.conditionsComp = re.compData || [];
      if (re.filterDefault) {
        // 保存默认的过滤条件
        this.setDefaults(2, this.conditions);
      }
      if (re.compDefault) {
        // 保存默认的对比条件
        this.setDefaults(1, this.conditionsComp);
      }
      this.getDefaultCompare();
    },
    /**
     * @description:基础数据内容保存
     * @param {*}
     * @return {*}
     */
    saveBaseData(par) {
      this.setBaseData = par;
    },
    /**
     * @description: 选择基础数据
     * @param {*}
     * @return {*}
     */
    showBaseData(row) {
      this.$refs.popBaseData.visiable = true;
      this.filterRowData = row;
    },
    /**
     * @description: 获取对比人id
     * @param {*}
     * @return {*}
     */
    getCompareUser() {
      const ids = this.id.split(",");
      // eslint-disable-next-line
      for (const i in ids) {
        ids[i] = ids[i] === "mine" ? this.userInfo.userId : ids[i];
      }
      this.compUserIds = ids.join(",");
    },
    /**
     * @description: 获取默认的对比条件
     * @param {*}
     * @return {*}
     */
    async getDefaultCompare() {
      const pa = { uniqueId: this.templateId, funcType: 1 };
      this.conditionsComp = await defaultListService.queryDefaults(pa);
      console.log(this.conditionsComp, "conditionsCompconditionsComp");
      this.getDefaultFilter();
    },
    /**
     * @description: 获取默认的过滤条件
     * @param {*}
     * @return {*}
     */
    async getDefaultFilter() {
      const pa = { uniqueId: this.templateId, funcType: 2 };
      this.conditions = await defaultListService.queryDefaults(pa);
      console.log(this.conditions, "conditionsconditionsconditions");
      this.getList();
    },
    /**
     * @description: 获取对比内容
     * @param {*}
     * @return {*}
     */
    async getList() {
      const pa = {
        templateId: this.templateId,
        conditions: JSON.stringify(this.conditions),
        compareUserIds: this.compUserIds,
        sortField: JSON.stringify({ field: this.timeField, sortType: "000" })
      };
      const contentList = await defaultListService.ptCompareList(pa);
      contentList.forEach((item) => {
        item.date = dayjs(item.createTime).format("YYYY-MM-DD");
      });
      const res = this.groupByKey(contentList);
      console.log(res);
      this.handlerData(contentList);
      this.ptFieldList();
    },
    groupByKey(list) {
      return list.reduce((acc, item) => {
        if (!acc[item.date]) {
          acc[item.date] = [item];
        } else {
          acc[item.date].push(item);
        }
        return acc;
      }, {});
    },
    /**
     * @description: 处理对比数据
     * @param {*}
     * @return {*}
     */
    handlerData(data) {
      this.compTime = [];
      const cIds = this.compUserIds.split(",");
      for (let i = 0; i < cIds.length; i++) {
        // 对比人id
        for (let j = 0; j < data.length; j++) {
          // 对比数据
          const ownerId = data[j].ownerId;
          const tf = data[j][this.timeField];
          if (ownerId === cIds[i] && tf) {
            const date = tf.split(" ")[0];
            if (this.compTime.indexOf(date) < 0) {
              this.compTime.push(date);
            }
            // 拼标题
            let h = "";
            for (let k = 0; k < this.conditionsComp.length; k++) {
              const fkey = this.conditionsComp[k].field;
              const fieldType = Number(this.conditionsComp[k].fieldType);
              if (Object.prototype.hasOwnProperty.call(data[j], fkey)) {
                let _name = "";
                if (fieldType === 3 || fieldType === 4) {
                  console.log(h);
                } else if (fieldType === 7) {
                  console.log(h);
                } else if (Object.prototype.toString.apply(data[j][fkey]) === "[object Array]") {
                  console.log(h);
                } else if (!Object.keys(data[j][fkey]).length) {
                  console.log(h);
                } else {
                  _name = data[j][fkey];
                }
                h += _name;
              }
            }
            // eslint-disable-next-line
            continue;
          }
        }
      }
    },
    /**
     * @description: 获取过滤字段、对比字段
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async ptFieldList() {
      const pa = {
        templateId: this.templateId
      };
      const rsp = await defaultListService.ptFieldList(pa);
      this.colList = rsp || [];
      this.colList.forEach((b) => {
        b.fieldType = Number(b.fieldType);
      });
      this.compList = cloneDeep(this.colList);

      // bug33505
      for (let i = 0; i < this.conditionsComp.length; i++) {
        for (let j = 0; j < this.colList.length; j++) {
          if (this.conditionsComp[i].field === this.colList[j].field) {
            this.conditionsComp.splice(i, 1, this.colList[j]);
            break;
          }
        }
      }
    },
    /**
     * @description: 返回
     * @param {*}
     * @return {*}
     */
    back() {
      history.go(-1);
    },
    /**
     * @description: 过滤
     * @param {*}
     * @return {*}
     */
    filter() {
      this.$refs.popFilter.visiable = true;
    },
    /**
     * @description: 对比
     * @param {*}
     * @return {*}
     */
    compare() {
      this.$refs.popCompare.visiable = true;
    },
    /**
     * @description: 对比弹窗保存回调
     * @param {*}
     * @return {*}
     */
    submitCompare(ids) {
      console.log(ids);
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-set {
  background: #fff;
  .topbar {
    height: 50px;
    border-bottom: 1px solid #eaebf0;
    padding: 0 10px;
    position: relative;
    .pull-left {
      float: left;
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      .col-names {
        line-height: 34px;
        margin-left: 8px;
        color: #585858;
      }
      .en-select {
        margin-left: 8px;
      }
      .view-select {
        display: flex;
        align-items: center;
        .back-btn {
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
          color: #bbc4d3;
          cursor: pointer;
        }
        .tit {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .pull-right {
      float: right;
      display: flex;
      align-items: center;
      height: 50px;
      margin-right: 10px;
      .mods {
        display: flex;
        align-items: center;
        font-size: 12px;
        .btn-mod {
          display: flex;
          align-items: center;
          margin-left: 10px;
          cursor: pointer;
          .txts {
            margin-left: 2px;
          }
        }
      }
    }
    .back-btn {
      display: inline-block;
      vertical-align: middle;
      color: #aeaeae;
    }
  }
  .centbar {
    height: calc(100% - 50px);
  }
}
</style>
